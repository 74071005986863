import React, { useState } from 'react';

import { Popover, PopoverContent, PopoverTrigger } from '../../../../shared/Popover/Popover';
import PopoverSelectorTitle from '../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle';
import ColorSelector from '../../../../shared/ColorSelector/ColorSelector';
import TooltipDropdown from '../../../../shared/ToolTip/TooltipDropdown';


type Props = {
  textColor: string
  buttonColor: string
  onTextColorChange: (textColor: string) => void
  onButtonColorChange: (buttonColor: string) => void
}

const ColorToolbarSelector = ({ textColor, buttonColor, onButtonColorChange, onTextColorChange }: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const onTextColorHandle = (color: string) => {
    onTextColorChange(color)
    setPopoverOpen(false)
  }
  const onButtonColorHandle = (color: string) => {
    onButtonColorChange(color)
    setPopoverOpen(false)
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger >
        <TooltipDropdown
          tooltip='Color'
          side='top'
          container='button-element-popover-anchor'
          icon='faPalette'
        />
      </PopoverTrigger>
      <PopoverContent
        align="start"
        sideOffset={5}
        onOpenAutoFocus={e => e.preventDefault()}
        onCloseAutoFocus={e => e.preventDefault()}
        className="border-solid border w-60 border-gray-200 rounded-xl bg-white drop-shadow z-30"
      >
        <div className="p-4">
          <PopoverSelectorTitle title="Text color" />
          <ColorSelector color={textColor} setColor={onTextColorHandle} variant='small' />
        </div>
        <div className="p-4">
          <PopoverSelectorTitle title="Button color" />
          <ColorSelector color={buttonColor} setColor={onButtonColorHandle} variant='small' />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default ColorToolbarSelector;
import React from 'react';
import { withRef } from '@udecode/cn';
import { PlateLeaf } from '@udecode/plate-common/react';

import { useAppSelector } from '../../store/hooks/redux-hooks';
import { colorClassToRGB } from '../../utils/color.util';
import { systemColorsArray } from '../../shared/ColorSelector/SystemColorSelector';

export const TextHighlightLeaf = withRef<typeof PlateLeaf>(
  ({ children, ...props }, ref) => {
    const { activePalette } = useAppSelector(state => state.page_colors)
    const hightlightColor = props.leaf.text_highlight as string
    const highlightPalette = hightlightColor.startsWith('landing-')
    const color = systemColorsArray.find(color => color.name === hightlightColor)

    return (
      <PlateLeaf
        ref={ref}
        asChild
        {...props}
      >
        <span
          style={{ backgroundColor: highlightPalette ? color?.color : colorClassToRGB(hightlightColor, 100, activePalette.colors)! }}
        >
          {children}
        </span>
      </PlateLeaf>
    )
  }
);

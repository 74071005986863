import React from 'react';
import {
  BoldPlugin,
  ItalicPlugin,
  StrikethroughPlugin,
  UnderlinePlugin,
} from '@udecode/plate-basic-marks/react';
import { useEditorReadOnly } from '@udecode/plate-common/react';

import { MarkToolbarButton } from './FloatingToolbarElements/MarkToolbarButton/mark-toolbar-button';
import { TurnIntoDropdownMenu } from './FloatingToolbarElements/TurnIntoDropdown/turn-into-dropdown-menu';
import { AlignDropdownMenu } from './FloatingToolbarElements/AlignDropdown/align-dropdownmenu';
import Icon from '../../shared/Icon/Icon';
import ColorDropdown from './FloatingToolbarElements/ColorDropdown/ColorDropdown';
import { LinkToolbarButton } from './FloatingToolbarElements/LinkToolbarButton/LinkToolbarButton';
import SizeDropdown from './FloatingToolbarElements/SizeDropdown/SizeDropdown';


export function FloatingToolbarButtons() {
  const readOnly = useEditorReadOnly();
  return (
    <>
      {!readOnly && (
        <>
          <TurnIntoDropdownMenu />
          <ColorDropdown />
          <SizeDropdown />
          <MarkToolbarButton nodeType={BoldPlugin.key} tooltip="Bold">
            <Icon icon='faBold' />
          </MarkToolbarButton>
          <MarkToolbarButton nodeType={ItalicPlugin.key} tooltip="Italic">
            <Icon icon='faItalic' />
          </MarkToolbarButton>
          <MarkToolbarButton
            nodeType={UnderlinePlugin.key}
            tooltip="Underline"
          >
            <Icon icon='faUnderline' />
          </MarkToolbarButton>
          <MarkToolbarButton
            nodeType={StrikethroughPlugin.key}
            tooltip="Strikethrough"
          >
            <Icon icon='faStrikethrough' />
          </MarkToolbarButton>
          <LinkToolbarButton />
          <AlignDropdownMenu />
        </>
      )}
    </>
  );
}

import React from 'react';
import { withRef } from '@udecode/cn';
import { findNode, insertNodes, getParentNode, isBlockAboveEmpty, removeNodes, TElement, toggleBlock } from '@udecode/plate-common';
import { type PlateEditor, PlateElement, isEditorReadOnly, ParagraphPlugin } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { toggleList, unwrapList, getListRoot } from '@udecode/plate-list';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';

import {
  InlineCombobox, InlineComboboxContent, InlineComboboxEmpty,
  InlineComboboxInput, InlineComboboxItem,
} from '../InlineCombobox/InlineCombobox';
import Icon from '../../shared/Icon/Icon'
import { createDefaultVideoElement } from '../../plate-config/Plugins/Video/Video.plugin';
import { createDefaultEmbedElement } from '../../plate-config/Plugins/Embed/Embed.plugin';
import { createDefaultButtonGroupElement } from '../../plate-config/Plugins/Button/ButtonGroup.plugin';
import { createDefaultImageElement } from '../../plate-config/Plugins/Image/Image.plugin';
import { createDefaultAssessmentElement } from '../../plate-config/Plugins/Assessment/Assessment.plugin';
import { createDefaultPricingElement } from '../../plate-config/Plugins/Pricing/Pricing.plugin';
import { createDefaultAiTextBlockElement } from '../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { createDefaultEmojiElement } from '../../plate-config/Plugins/EmojiCommand/EmojiCommand.plugin';
import { createDefaultTemplateVariableInputElement } from '../../plate-config/Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin';
import { ColumnGroupPlugin, createDefaultColumnGroupElement } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import { createDefaultHrElement } from '../../plate-config/Plugins/HrLine/HrLine.plugin';
import { ColumnPlugin } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';
import { SectionPlugin } from '../../plate-config/Plugins/Section/Section.plugin';
import { createDefaultParagraphElement } from '../../plate-config/Plugins/DefaultMockups/DefaultMockups';

interface SlashCommandRule {
  icon: string;
  onSelect: (editor: PlateEditor) => void;
  value: string;
  keywords?: string[];
}

export const SlashInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;

    const isReadOnly = isEditorReadOnly(editor)
    const isTemplate = useAppSelector(state => state.page_addendums.template)
    const calc_variables = useAppSelector(state => state.page_calc_variables)
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const defaultSliderVar = calc_variables.find(variable => variable.slider_default)!

    if (isReadOnly) return <>{children}</>

    const slashNode = findNode(editor, { at: [], match: (n: any) => n.id === props.element.id })!
    const slashNodePath = slashNode[1]
    const parent = getParentNode(editor, slashNode[1])
    const highLevelParent = getParentNode(editor, parent![1])
    const isHighLevelParentColumn = highLevelParent ? highLevelParent[0].type === ColumnPlugin.key : false
    const isHighLevelParentSection = highLevelParent ? highLevelParent[0].type === SectionPlugin.key : false
    const isFirstColumnChild = isHighLevelParentColumn ? highLevelParent![0].children[0].id === parent![0].id : false
    const isFirstSectionChild = isHighLevelParentSection ? highLevelParent![0].children[0].id === parent![0].id : false

    const isListType = getListRoot(editor, parent![1])

    const firstLevelSectionChild = editor.children[slashNodePath[0]]?.children[slashNodePath[1]] as TElement
    const secondLevelSectionChild = firstLevelSectionChild?.children ? firstLevelSectionChild.children[slashNodePath[2]] as TElement : null
    const thirdLevelSectionChild = secondLevelSectionChild?.children ? secondLevelSectionChild.children[slashNodePath[3]] as TElement : null

    const isColumnDisabled = secondLevelSectionChild && secondLevelSectionChild.type === ColumnPlugin.key ||
      thirdLevelSectionChild && thirdLevelSectionChild.type === ColumnGroupPlugin.key

    const rules: SlashCommandRule[] = [
      {
        icon: 'faParagraph',
        keywords: ['p', 'paragraph'],
        onSelect: (editor) => {
          if (isListType) {
            unwrapList(editor)
          }
          return toggleBlock(editor, { type: ParagraphPlugin.key })
        },
        value: 'Paragraph',
      },
      {
        icon: 'faHeading',
        keywords: ['h1', 'heading 1'],
        onSelect: (editor) => {
          if (isListType) {
            unwrapList(editor)
          }
          return toggleBlock(editor, { type: HEADING_KEYS.h1 })
        },
        value: 'Heading 1',
      },
      {
        icon: 'faHeading',
        keywords: ['h2', 'heading 2'],
        onSelect: (editor) => {
          if (isListType) {
            unwrapList(editor)
          }
          return toggleBlock(editor, { type: HEADING_KEYS.h2 })
        },
        value: 'Heading 2',
      },
      {
        icon: 'faQuoteRight',
        keywords: ['blockquote', 'quote'],
        onSelect: (editor) => {
          if (isListType) {
            unwrapList(editor)
          }
          return toggleBlock(editor, { type: BlockquotePlugin.key })
        },
        value: 'Blockquote',
      },
      {
        icon: 'faListUl',
        keywords: ['ul', 'unordered list'],
        onSelect: (editor) => {
          if (parent) {
            const isListType = getListRoot(editor, parent![1])
            if (isListType && isListType[0].type === NumberedListPlugin.key) {
              return toggleList(editor, { type: BulletedListPlugin.key })
            }
            if (isListType && isListType[0].type === BulletedListPlugin.key) {
              return unwrapList(editor)
            }
            return toggleBlock(editor, { type: BulletedListPlugin.key })
          }
        },
        value: 'Bulleted list',
      },
      {
        icon: 'faListOl',
        keywords: ['ol', 'ordered list'],
        onSelect: (editor) => {
          if (parent) {
            const isListType = getListRoot(editor, parent![1])
            if (isListType && isListType[0].type === BulletedListPlugin.key) {
              return toggleList(editor, { type: NumberedListPlugin.key })
            }
            if (isListType && isListType[0].type === NumberedListPlugin.key) {
              return unwrapList(editor)
            }
            return toggleBlock(editor, { type: NumberedListPlugin.key })
          }
        },
        value: 'Numbered list',
      },
      {
        icon: 'faFaceSmile',
        keywords: ['emoji'],
        onSelect: (editor) => insertNodes(editor, createDefaultEmojiElement(), { at: slashNode[1] }),
        value: 'Emoji',
      },
      {
        icon: 'faWindowMinimize',
        keywords: ['hr', 'horizontal line'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultHrElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultHrElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultHrElement(), createDefaultParagraphElement()])
        },
        value: 'Horizontal line',
      },
      ...(isColumnDisabled ? [] : [{
        icon: 'faTableColumns',
        keywords: ['column'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultColumnGroupElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultColumnGroupElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultColumnGroupElement(), createDefaultParagraphElement()])
        },
        value: 'Column Group',
      }]),
      {
        icon: 'faVideo',
        keywords: ['video'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultVideoElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultVideoElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultVideoElement(), createDefaultParagraphElement()])
        },
        value: 'Video',
      },
      {
        icon: 'faFileImport',
        keywords: ['embed'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultEmbedElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultEmbedElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultEmbedElement(), createDefaultParagraphElement()])
        },
        value: 'Embed',
      },
      {
        icon: 'faArrowUpRightFromSquare',
        keywords: ['btn', 'button'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultButtonGroupElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultButtonGroupElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultButtonGroupElement(), createDefaultParagraphElement()])
        },
        value: 'Button',
      },
      {
        icon: 'faImage',
        keywords: ['img', 'image'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultImageElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultImageElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultImageElement(), createDefaultParagraphElement()])
        },
        value: 'Image',
      },
      {
        icon: 'faCalculator',
        keywords: ['calc', 'value', 'assessment'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultAssessmentElement(defaultSliderVar.id), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultAssessmentElement(defaultSliderVar.id), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultAssessmentElement(defaultSliderVar.id), createDefaultParagraphElement()])
        },
        value: 'Value Calculator',
      },
      {
        icon: 'faDollarSign',
        keywords: ['pricing', 'table'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultPricingElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultPricingElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultPricingElement(), createDefaultParagraphElement()])
        },
        value: 'Pricing table',
      },
      ...(isTemplate ? [{
        icon: 'faWandMagicSparkles',
        keywords: ['ai', 'text', 'block', 'aitextblock'],
        onSelect: (editor) => {
          const isListType = getListRoot(editor, parent![1])
          if (isListType) {
            const path = isListType[1]
            path[path.length - 1] += 1
            return insertNodes(editor, [createDefaultAiTextBlockElement(), createDefaultParagraphElement()], { at: path })
          }
          if (isBlockAboveEmpty(editor)) {
            parent && !isFirstColumnChild && !isFirstSectionChild && removeNodes(editor, { at: parent[1] })
            return insertNodes(editor, createDefaultAiTextBlockElement(), { at: parent![1] })
          }
          insertNodes(editor, [createDefaultAiTextBlockElement(), createDefaultParagraphElement()])
        },
        value: 'AI Text Block',
      }] : []),
      ...(isTemplate ? [{
        icon: 'faSpellCheck',
        keywords: ['variable', 'template variable', 'template'],
        onSelect: (editor) => insertNodes(editor, createDefaultTemplateVariableInputElement(), { at: slashNode[1] }),
        value: 'Template Variable',
      }] : [])
    ];

    return (
      <PlateElement
        as="span"
        data-slate-value={element.value}
        ref={ref}
        data-id={element.id}
        {...props}
      >
        <InlineCombobox element={element} trigger="/">
          <InlineComboboxInput />
          <InlineComboboxContent className="force-scroll-visible max-h-80 w-72">
            <InlineComboboxEmpty>
              No matching commands found
            </InlineComboboxEmpty>
            {rules.map(({ icon, keywords, onSelect, value }) => (
              <InlineComboboxItem
                key={value}
                keywords={keywords}
                onClick={() => onSelect(editor)}
                value={value}
              >
                <Icon icon={icon} className="mr-4 size-4" shouldBeHidden={true} />
                {value}
              </InlineComboboxItem>
            ))}
          </InlineComboboxContent>
        </InlineCombobox>
        {children}
      </PlateElement>
    );
  }
);

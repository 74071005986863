import React, { useState } from "react";

import { useAppSelector } from "../../../../store/hooks/redux-hooks";
import IconButton from "../../../../shared/IconButton/IconButton";
import VariableCard from "./VariableCard/VariableCard";
import VariableForm from "./VariableForm/VariableForm";
import {
  CreateCalcVariableInput, useCreateCalcVariableMutation,
  useDeleteCalcVariableMutation, useUpdateCalcVariableMutation
}
  from "../../../../store/reducers/api/calc_variables/calc_variables.api";
import { PageVariable } from "../../../../store/reducers/api/api.types";

const CalculationVariables = () => {
  const calc_variables = useAppSelector(state => state.page_calc_variables)
  const [formId, setFormId] = useState<string | null>(null)
  const [deleteCalcVar, { isLoading: isDeleteVarLoading }] = useDeleteCalcVariableMutation()
  const [updateCalcVar, { isLoading: isUpdateVarLoading }] = useUpdateCalcVariableMutation()
  const [createCalcVar, { isLoading: isCreateVarLoading }] = useCreateCalcVariableMutation()

  const handleVariableCreate = async (variable: CreateCalcVariableInput) => await createCalcVar(variable).then(() => setFormId(null))
  const handleVariableUpdate = async (variable: PageVariable) => await updateCalcVar(variable).then(() => setFormId(null))
  const handleVariableDelete = async (varId: string) => await deleteCalcVar(varId).then(() => setFormId(null))

  const isLoading = isDeleteVarLoading || isUpdateVarLoading || isCreateVarLoading;

  return (
    <div className="flex flex-col gap-4">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-900 bg-opacity-50 flex flex-col items-center justify-center z-50">
          <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500" />
        </div>
      )}
      <p className="text-gray-600 text-sm">
        Variables are used for assessment calculations and in the pricing tables.
      </p>
      {calc_variables.map((variable) =>
        <VariableCard
          key={variable.id}
          variable={variable}
          handleVariableUpdate={handleVariableUpdate}
          handleVariableDelete={handleVariableDelete}
          setFormId={setFormId}
          formId={formId}
        />
      )}
      {formId === 'new'
        ?
        <div className={`border-solid border rounded-xl border-blue-300 ring ring-blue-200 ring-opacity-50 shadow-sm`} >
          <VariableForm
            onFormClose={setFormId.bind(setFormId, null)}
            onVariableCreate={handleVariableCreate}
          />
        </div>
        :
        <IconButton
          btnClassName="btn btn-white btn-block"
          icon="faPlus"
          textAfter="Add Variable"
          iconClassName="mr-1 text-gray-600"
          onClick={setFormId.bind(setFormId, 'new')}
        />
      }
      <p className="text-gray-600 mb-12 text-sm">
        The end user can see and adjust variables in the proposal.
      </p>
    </div>
  );
}

export default CalculationVariables;
import React, { useEffect, useMemo, useState } from 'react';
import { TElement } from '@udecode/plate-common';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Plate } from '@udecode/plate-common/react';
import isEqual from 'react-fast-compare';

import { Editor } from '../plate-components/EditorContainer/editor';
import { FloatingToolbar } from '../plate-components/FloatingToolbar/floating-toolbar';
import { FloatingToolbarButtons } from '../plate-components/FloatingToolbar/floating-toolbar-buttons';
import { useAppSelector, useAppDispatch } from '../store/hooks/redux-hooks';
import { setSectionsFromEditor } from '../store/reducers/page_sections/page_sections';
import { useSaveSectionDataMutation } from '../store/reducers/api/sections/sections';
import { hasMoreThanThreeSecPassed } from '../utils/time.util';
import { updateShadowCalcVariables } from '../store/reducers/page-shadow-store/page_shadow_store';
import useAppEditor from './PlateConfig';

const PlateEditor = () => {
  const dispatch = useAppDispatch()
  const page_sections = useAppSelector(state => state.page_sections)
  const isLandingPage = useAppSelector(state => state.page_addendums.isLandingPage)
  const isTemplate = useAppSelector(state => state.page_addendums.template)
  const calc_vars = useAppSelector(state => state.page_calc_variables)

  const initialSections = page_sections.sections as TElement[]
  const lastUpdated = page_sections.lastUpdated
  const lastSavedSections = page_sections.lastSavedSections as TElement[]

  const editorMode = useAppSelector(state => state.page_addendums.readOnly)
  const [saveEditorStateToApi, { isLoading }] = useSaveSectionDataMutation()

  const [editorState, setEditorState] = useState(initialSections)

  // ----------- SHADOW STORE CONTROLLER -----------
  useEffect(() => {
    if (editorMode || isLandingPage) {
      dispatch(updateShadowCalcVariables(calc_vars))
    }
  }, [editorMode, isLandingPage])
  // ----------- SHADOW STORE CONTROLLER -----------

  // ----------- EDITOR_DATA SAVING PROCESS -----------
  //  CRITICAL: Save data to API if more than 5 seconds have passed since last update
  const shouldSyncWithAPI = async () => {
    if (!isEqual(editorState, lastSavedSections) && !isLoading) {
      await saveEditorStateToApi(editorState)
    }
  }
  // CRITICAL: Save data to API in case user stoped adding content and shouldSyncWithAPI was not triggered
  // during last 5 seconds
  useEffect(() => {
    const intervalId = setInterval(async () => {
      await shouldSyncWithAPI()
    }, 5 * 1000);
    return () => clearInterval(intervalId);
  }, [editorState, lastSavedSections]);

  //  CRITICAL: Save data to API if more than 3 seconds have passed since last update
  const syncWithAPI = async () => {
    if (hasMoreThanThreeSecPassed(new Date(lastUpdated)) && !isEqual(editorState, page_sections.sections) && !isLoading) {
      await saveEditorStateToApi(editorState)
    }
  }
  // ----------- EDITOR_DATA SAVING PROCESS -----------

  // CRITICAL: This is workaround to track editor changes
  useEffect(() => {
    //  CRITICAL: This is not only one place where we save data to API, but it is the most important one.
    //  second place to check saving process is in App.tsx
    if (isLandingPage || editorMode) return
    console.log('editorState', editorState);
    // sync with API
    syncWithAPI()
    // set editor state to redux store
    dispatch(setSectionsFromEditor(editorState))
  }, [editorState, isLandingPage, editorMode])

  //CRITICAL: useMemo is used to prevent re-creating editor instance on every render 
  const editor = useMemo(() => useAppEditor(isTemplate, editorState), []);
  const onEditorValueChange = ({ value }) => setEditorState(value)

  return (
    <DndProvider backend={HTML5Backend}>
      <Plate
        editor={editor}
        onChange={onEditorValueChange}
        readOnly={isLandingPage ? isLandingPage : editorMode}
      >
        <Editor />
        {!editorMode &&
          <FloatingToolbar>
            <FloatingToolbarButtons />
          </FloatingToolbar>
        }
      </Plate>
    </DndProvider>
  );
}

export default PlateEditor;
import { TElement } from "@udecode/plate-common"
import { createPlatePlugin } from "@udecode/plate-common/react"


export const EmbedPlugin = createPlatePlugin({
  key: 'embed',
  node: {
    type: 'embed',
    isElement: true,
    isVoid: true,
  }
})

export interface PlateElementEmbed extends TElement {
  id: string
  type: typeof EmbedPlugin.key
  url: string | null
}

export const createDefaultEmbedElement = (): TElement => ({
  type: EmbedPlugin.key,
  url: null,
  children: [{ text: '' }],
})
import React from 'react';

import EditableField from "../../../../shared/EditableField/EditableField";

type Props = {
  onPromtChange: (prompt: string) => void
  promt: string | null
}

const Promt: React.FC<Props> = ({ promt, onPromtChange }) => {
  return (
    <div className="mt-6">
      <label>Promt</label>
      <EditableField
        placeholder="Type your promt here..."
        value={promt || ''}
        suppressCreatingNewLines={false}
        rows={4}
        onChange={onPromtChange}
        wrapperClassName="w-[100%] border border-gray-300 rounded-md"
        inputClassName="text-sm"
        styleOptions={{ focusStyles: false }}
      />
    </div>
  )
}

export default Promt;
import { Controller } from "@hotwired/stimulus"

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from '../App.tsx'
import GlobalProviders from '../components/HOC/GlobalProviders.tsx'

export default class extends Controller {
  connect() {
    console.log("ReactController connected")
    this.reactRoot = ReactDOM.createRoot(this.element)
    this.reactRoot.render(
      <GlobalProviders>
        <App />
      </GlobalProviders>
    )
  }

  disconnect() {
    if (this.reactRoot) {
      this.reactRoot.unmount()
    }
  }
}

import React from 'react';

import ColorSelector from '../../../shared/ColorSelector/ColorSelector';

type Props = {
  hrId: string
  hrColor: string
  onHrColorChange: (hrColor: string) => void
}

const HrColorSelector = ({ hrColor, onHrColorChange }: Props) => {

  const onHrColorHandle = (color: string) => {
    onHrColorChange(color)
  }

  // NOTE: The color selector has a mt-1 on it
  return (
    <div className="px-2 mb-1">
      <ColorSelector color={hrColor} setColor={onHrColorHandle} variant='small' />
    </div>
  )
}

export default HrColorSelector;

import React, { useEffect } from 'react';
import { withRef } from '@udecode/cn';
import { findNode, } from '@udecode/plate-common';
import { PlateElement, isEditorReadOnly } from '@udecode/plate-common/react';
import { PanelGroup } from "react-resizable-panels";
import { useSelected } from 'slate-react';

import { Popover, PopoverContent, PopoverAnchor } from "../../shared/Popover/Popover";
import TooltipIcon from '../../shared/ToolTip/TooltipIcon';
import { PlateColumnGroupElement } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import { PlateSectionElement } from '../../plate-config/Plugins/Section/Section.plugin';
import TooltipButton from '../../shared/ToolTip/TooltipButton';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { createDefaultColumnElement } from '../../plate-config/Plugins/ColumnGroup/Column.plugin';

export const ColumnGroupElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const selected = useSelected();
    const isMobilePreview = useAppSelector(state => state.page_addendums.isMobilePreviewActive)
    const isReadOnly = isEditorReadOnly(editor)

    useEffect(() => {
      editor.setNodes({ selected } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })
    }, [selected])

    const block = element as PlateColumnGroupElement
    const childAmount = block.children.length
    const node = findNode(editor, { at: [], match: (n: any) => n.id === element.id })!
    const nodePath = node[1]

    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const section = editor.children[node[1][0]] as PlateSectionElement
    const isWideContent = section.width === 'large'
    const sectionChildren = section.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

    const onTwoColumnsHandle = (): void => {
      if (childAmount === 2) return;
      if (childAmount >= 3) {
        element.children.slice(2, childAmount).forEach((child: any) => {
          editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
        });
      }
    };

    const onThreeColumnsHandle = (): void => {
      if (childAmount === 3) return;
      switch (childAmount) {
        case 2:
          editor.insertNodes(createDefaultColumnElement(), { at: [...nodePath, block.children.length] });
          break;
        case 4:
          editor.removeNodes({ at: [], match: (n: any) => n.id === element.children[3].id });
          break;
      }
    };

    const onFourColumnsHandle = (): void => {
      if (childAmount === 4) return;
      const insertCount = 4 - childAmount;
      for (let i = 0; i < insertCount; i++) {
        editor.insertNodes(createDefaultColumnElement(), { at: [...nodePath, block.children.length + i] });
      }
    };

    const onDeleteColumnsHandle = () =>
      editor.removeNodes({ at: [], match: (n: any) => n.id === element.id })

    const onWidthModeHandle = () =>
      editor.setNodes({ style: block.style ? null : 'wide' } as Partial<Node>, { at: [], match: (n: any) => n.id === element.id })

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        data-id={block.id}
        data-plate-selectable
        className={`
        ${className} 
        page-block group-column-block 
        ${block.style === 'wide' && !isWideContent && !isMobilePreview ? 'relative left-[50%] translate-x-[-50%] w-[1022px]' : ''}
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        ${!isReadOnly ? 'group/column-wrapper' : ''}
         `}
        {...props}
      >
        <Popover open={isReadOnly ? false : selected} >
          <PopoverAnchor>
            <PanelGroup
              id={element.id as string}
              key={`${element.children.map((child) => child.id).toString()}`}
              className="panel_group_resizer"
              direction="horizontal"
            >
              {children}
            </PanelGroup>
          </PopoverAnchor>
          <PopoverContent
            onOpenAutoFocus={e => e.preventDefault()}
            onCloseAutoFocus={e => e.preventDefault()}
            side='top'
            sideOffset={10}
            align='center'
            className={`
            flex gap-2 p-1 w-auto border-solid border border-gray-200 rounded-xl bg-gray-100 text-gray-900 
            drop-shadow font-sans whitespace-nowrap bg-popover px-3 opacity-100 shadow-sm print:hidden outline-none
            `}
          >
            <TooltipButton
              tooltip='Two columns'
              side='top'
              container={element.id as string}
              icon='twoColumn'
              onClick={onTwoColumnsHandle}
              btnClassName={`btn-small flex items-center rounded-lg hover:bg-white ${childAmount === 2 ? 'bg-gray-300' : ''}`}
              disabled={childAmount === 2}
            />
            <TooltipButton
              tooltip='Three columns'
              side='top'
              container={element.id as string}
              icon='threeColumn'
              onClick={onThreeColumnsHandle}
              btnClassName={`btn-small flex items-center rounded-lg hover:bg-white ${childAmount === 3 ? 'bg-gray-300' : ''}`}
              disabled={childAmount === 3}
            />
            <TooltipButton
              tooltip='Four columns'
              side='top'
              container={element.id as string}
              icon='fourColumn'
              onClick={onFourColumnsHandle}
              btnClassName={`btn-small flex items-center rounded-lg hover:bg-white ${childAmount === 4 ? 'bg-gray-300' : ''}`}
              disabled={childAmount === 4}
            />
            <TooltipButton
              tooltip={block.style === 'wide' ? "Adjustable mode" : "Wide mode"}
              side='top'
              container={element.id as string}
              icon={block.style === 'wide' ? "faMinimize" : "faUpRightAndDownLeftFromCenter"}
              onClick={onWidthModeHandle}
              btnClassName={`
              btn btn-small flex items-center rounded-lg
              hover:bg-white focus:outline-none focus:shadow-none
              focus:ring-0 ring-0 ring-offset-0 focus:ring-offset-0 shadow-none
              `}
              disabled={isWideContent}
            />
            <TooltipIcon
              tooltip="Delete columns"
              side="top"
              container={element.id as string}
              icon="faTrash"
              iconWrapperClassName="btn-small flex items-center rounded-lg hover:bg-red-100"
              onClick={onDeleteColumnsHandle}
            />
          </PopoverContent>
        </Popover>
      </PlateElement>
    );
  }
);



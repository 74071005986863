import React, { useEffect, useState } from "react";
import { findNode } from "@udecode/plate-common";
import { PlateRenderElementProps, isEditorReadOnly } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";

import embedUrlPatterns, { EmbedPlatform, EmbedConfig } from "./config/config";
import EmbedViewController from "./EmbedViewController";
import EmbedSourceItem from "./EmbedSourceItem";
import { PlateElementEmbed } from "../../../components/plate-config/Plugins/Embed/Embed.plugin";
import IconButton from '../../shared/IconButton/IconButton';
import { useAppSelector } from "../../store/hooks/redux-hooks";
import TooltipIcon from "../../shared/ToolTip/TooltipIcon";
import { selectedBlockClasses } from "../../utils/color.util";

const EmbedVoidElement = ({ attributes, children, element, editor }: PlateRenderElementProps) => {
  const isReadOnly = isEditorReadOnly(editor)
  const [link, setLink] = useState('');
  const [platform, setPlatform] = useState<EmbedConfig | null>(null)
  const [error, setError] = useState<null | string>(null);
  const [isEditing, setIsEditing] = useState(false);
  const selected = useSelected()

  const block = element as PlateElementEmbed
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
  const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
  const activeSection = editor.children[nodePath[0]];
  const sectionChildren = activeSection.children.filter(child => !!child.type)
  const isFirstChild = sectionChildren[0].id === element.id;
  const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

  useEffect(() => {
    if (block?.url) {
      setLink(block.url)
      validatePlatformUrl(block.url)
    }
  }, [block])

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setLink(value)
    setError(null)
  };

  const onConfirm = () => {
    if (link) {
      validatePlatformUrl(link)
    } else {
      setError('Please enter a valid URL')
    }
  };

  const onEditorStateUpdate = (url: string) =>
    editor.setNodes({ url } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

  const validatePlatformUrl = (link: string) => {
    for (let config of embedUrlPatterns) {
      const match = link.match(config.pattern);
      if (match) {
        const isGoogleSheets = config.name === EmbedPlatform.GoogleSheets;
        if (isGoogleSheets) {
          const parsedUrl = new URL(link);
          if (parsedUrl.searchParams.has('output')) {
            setError('Unsupported URL type');
            return;
          }
        }
        setPlatform({
          ...config,
          link,
        })
        onEditorStateUpdate(link);
        setIsEditing(false);
        setError(null);
        return;
      }
    }
    setError('Unsupported URL type');
  }

  const onElementDeleteHandle = () => editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })

  if (isReadOnly) return platform ?
    <div
      className={`
      page-block embed-block
      ${isFirstChild ? 'first-section-child' : ""} 
      ${isLastChild ? 'last-section-child' : ""}
      `}
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-id={block.id}
      {...attributes}
    >
      <EmbedViewController platform={platform} />
    </div>
    : null

  return (
    <div
      className={`
      page-block embed-block
      ${isFirstChild ? 'first-section-child' : ""} 
      ${isLastChild ? 'last-section-child' : ""}
      ${selected ? selectedBlockClasses : ""}
      `}
      data-plate-selectable
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-id={block.id}
      {...attributes}
    >
      {platform && !isEditing ? (
        <div className={`relative ${selected ? 'pt-[5px]' : ""}`}>
          {selected && <div className="absolute top-[1px] right-[2px]">
            <button
              onClick={setIsEditing.bind(setIsEditing, true)}
              className="btn btn-xs btn-white"
            >
              Edit Embed
            </button>
          </div>}
          <EmbedViewController platform={platform} />
        </div>
      ) : (
        <div className="w-full min-h-96 bg-gray-200/80 border-gray-400 rounded-xl shadow-sm text-center p-8 pt-[37px]">
          <TooltipIcon
            tooltip="Delete"
            icon="faTrash"
            iconWrapperClassName="btn-small rounded-lg hover:bg-red-100 absolute text-gray-800 right-1 top-1"
            side="top"
            container={block.id}
            onClick={onElementDeleteHandle}
          />
          <div className={`form-group mb-3 ${error ? "error" : ""}`}>
            <div className="flex gap-2">
              <input
                type="text"
                className="form-control flex-grow w-full text-xs sm:text-xs md:text-base text-gray-700"
                placeholder="Insert embed link"
                onChange={onInputChanged}
                value={link}
              />
              <IconButton
                btnClassName='btn btn-primary only-child'
                icon='faCheck'
                onClick={onConfirm}
              />
            </div>
            {error && <div className="form-hint text-left error">{error}</div>}
          </div>
          <div className="flex items-center justify-center">
            <div className="text-gray-800">
              <h3 className="mb-2">Embed content from:</h3>
              <ul className="list-none text-base">
                {embedUrlPatterns.map((platform, i) => <EmbedSourceItem platform={platform} key={i} />)}
              </ul>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  )
}

export default EmbedVoidElement

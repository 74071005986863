import React from 'react';
import { withRef } from '@udecode/cn';
import { insertNodes } from '@udecode/plate-common';
import { PlateElement, isEditorReadOnly } from '@udecode/plate-common/react';

import {
  InlineCombobox,
  InlineComboboxContent,
  InlineComboboxEmpty,
  InlineComboboxInput,
  InlineComboboxItem,
} from '../InlineCombobox/InlineCombobox';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { TemplateVariablePlugin } from '../../plate-config/Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin';


export const TemplateVariableInputElement = withRef<typeof PlateElement>(
  ({ className, ...props }, ref) => {
    const { children, editor, element } = props;

    const isReadOnly = isEditorReadOnly(editor)
    const isTemplate = useAppSelector(state => state.page_addendums.template)
    const template_variables = useAppSelector(state => state.page_template_variables)

    if (!isTemplate || isReadOnly) return <>{children}</>

    const onVariableSelect = (variableId: string) =>
      insertNodes(editor, { type: TemplateVariablePlugin.key, children: [{ text: "" }], variableId })

    return (
      <PlateElement
        as="span"
        data-slate-value={element.value}
        ref={ref}
        data-id={element.id}
        {...props}
      >
        <InlineCombobox element={element} trigger="{">
          <InlineComboboxInput />
          <InlineComboboxContent className="max-h-96 w-96">
            <InlineComboboxEmpty>
              No matching variables found
            </InlineComboboxEmpty>
            {template_variables.map((variable) => (
              <InlineComboboxItem
                key={variable.name}
                keywords={[variable.about, variable.name, variable.value]}
                onClick={onVariableSelect.bind(onVariableSelect, variable.value)}
                value={variable.value}
                className='h-auto border-b border-gray-100'
              >
                <div className="px-1 flex flex-col w-full justify-between">
                  <div className="font-semibold text-sm text-gray-700">{variable.name}</div>
                  <div className="text-xs text-gray-500">{variable.about}</div>
                </div>
              </InlineComboboxItem>
            ))}
          </InlineComboboxContent>
        </InlineCombobox>
        {children}
      </PlateElement>
    );
  }
);

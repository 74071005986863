export enum EmbedPlatform {
    Facebook = "Facebook",
    Instagram = "Instagram",
    Twitter = "Twitter",
    LinkedIn = "LinkedIn",
    Pitch = "Pitch",
    Miro = "Miro",
    GoogleForms = "Google Forms",
    GoogleSlides = "Google Slides",
    GoogleDocs = "Google Docs",
    GoogleSheets = "Google Sheets",
    Calendly = "Calendly",
    HubSpotForms = "HubSpot Forms",
    TikTok = "TikTok",
}

export interface EmbedConfig {
    name: EmbedPlatform;
    linkFormat: string;
    pattern: string | RegExp;
    learnMore?: string;
    link?: string;
}

const embedUrlPatterns: EmbedConfig[] = [
    { name: EmbedPlatform.Facebook, linkFormat: "https://www.facebook.com/username/posts/XXX", pattern: "^https:\\/\\/www\\.facebook\\.com\\/(?:[^\\/]+)\\/posts\\/(pfbid\\w+|\\d+)" },
    // https://www.facebook.com/andrewismusic/posts/451971596293956
    // https://www.facebook.com/Patriotshockey/posts/pfbid02wGjeGJFqVEYaLk9CKJrYWneFD6hvHbn76yYYwiWVfNh6xmxiRAPCvkTgwoEZjCF4l

    { name: EmbedPlatform.Instagram, linkFormat: "https://www.instagram.com/p/XXX/", pattern: /^https:\/\/www\.instagram\.com\/p\/([^\/]+)/ },
    // https://www.instagram.com/p/CUbHfhpswxt/

    { name: EmbedPlatform.Twitter, linkFormat: "https://twitter.com/username/status/XXX", pattern: /^https:\/\/twitter\.com\/(?:[^\/]+)\/status\/(\d+)/ },
    // https://twitter.com/PixelAndBracket/status/1356633038717923333

    {
        name: EmbedPlatform.LinkedIn, linkFormat: "https://www.linkedin.com/embed/feed/update/urn:li:XXX:XXX",
        pattern: /^https:\/\/www\.linkedin\.com\/embed\/feed\/update\/urn:li:([a-zA-Z0-9_]+):(\d+)/,
        learnMore: "https://revvedup.freshdesk.com/en/support/solutions/articles/201000047344-create-an-embedded-linkedin-post"
    },
    // https://www.linkedin.com/embed/feed/update/urn:li:share:7158912895223832576
    // https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7134927365633118211

    { name: EmbedPlatform.Pitch, linkFormat: "https://pitch.com/v/XXX", pattern: /^https:\/\/pitch\.com\/([^\/]+)/ },
    // https://pitch.com/v/untitled-presentation-p3jxqk

    { name: EmbedPlatform.Miro, linkFormat: "https://miro.com/app/board/boardname/?share_link_id=XXX", pattern: /^https:\/\/miro\.com\/app\/(board|card)\/([^\/]+)/ },
    // https://miro.com/app/board/uXjVNxXaang=/?share_link_id=598020075755
    {
        name: EmbedPlatform.GoogleForms,
        linkFormat: "https://docs.google.com/forms/d/XXX",
        pattern: "^(https:\\/\\/docs\\.google\\.com\\/forms\\/d\\/(e\\/)?([^\\/]+)(\\/edit)?|https:\\/\\/forms\\.gle\\/([a-zA-Z0-9_-]+))"
    },
    // https://docs.google.com/forms/d/e/1FAIpQLSdL51tSpwizTHCDPIJkdIHoG0jtmY-p8JTzzkmoZoJYmpIoRw/viewform?usp=sf_link

    { name: EmbedPlatform.GoogleSlides, linkFormat: "https://docs.google.com/presentation/d/XXX", pattern: /^https:\/\/docs\.google\.com\/presentation\/d\/([^\/]+)/ },
    // https://docs.google.com/presentation/d/e/2PACX-1vRddHwfbvewiho7ZAwgke_0We4sIn1-L1jgBa8Xetcv1okjOpmpw1rvuqGR3KQZtjq3VOOATGx1Upmu/pub?start=false&loop=false&delayms=3000

    { name: EmbedPlatform.GoogleDocs, linkFormat: "https://docs.google.com/document/d/XXX", pattern: /^https:\/\/docs\.google\.com\/document\/d\/([^\/]+)/ },
    // https://docs.google.com/document/d/e/2PACX-1vTfcO3_dAPnbioDv4sRTlFXq55BX4QnWjjXWevNNwV-tLDLIekfLaeY6feYAchp2nE_fHsKd-TYvmtu/pub

    { name: EmbedPlatform.GoogleSheets, linkFormat: "https://docs.google.com/spreadsheets/d/XXX", pattern: /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([^\/]+)/ },
    // https://docs.google.com/spreadsheets/d/e/2PACX-1vSMxrB12-UMUR-oQJLPCBCyJqFngySLmccVcyIqZZnTHOCiIVab5FhHv1CoKVMTRvumgv3cNt1vqwV-/pubhtml

    { name: EmbedPlatform.Calendly, linkFormat: "https://calendly.com/XXX", pattern: /^https:\/\/calendly\.com\/([^\/]+)/ },
    // https://calendly.com/dmitriy-mam
    // https://calendly.com/dmitriy-mam/embed-test

    { name: EmbedPlatform.HubSpotForms, linkFormat: "https://share-XXX.hsforms.com/XXX", pattern: "^https:\\/\\/share(?:-(eu1|na1|na2))?\\.hsforms\\.com\\/([a-zA-Z0-9_-]+)" },
    // https://share-eu1.hsforms.com/1esounRIxSpm7HeXjhTFTDA2dsklq
    { name: EmbedPlatform.TikTok, linkFormat: "https://www.tiktok.com/@username/video/XXX", pattern: /^https:\/\/www\.tiktok\.com\/@([^\/]+)\/video\/(\d+)/ },
    // https://www.tiktok.com/@leorakharkiv/video/7337719295469718789?is_from_webapp=1&sender_device=pc
];

export default embedUrlPatterns;

import React, { useState } from "react"

import { Popover, PopoverArrow, PopoverContent, PopoverTrigger } from "../../../../shared/Popover/Popover"
import { PlateSectionElement } from "../../../../plate-config/Plugins/Section/Section.plugin"
import TooltipIcon from "../../../../shared/ToolTip/TooltipIcon"
import StyleOptions from "./StyleTabs/StyleOptions/StyleOptions"
import FontOptions from "./StyleTabs/FontOptions/FontOptions"
import AnimateOptions from "./StyleTabs/AnimateOptions/AnimateOptions"


type Props = {
  sectionElement: PlateSectionElement
}

const StyleMenu = ({ sectionElement }: Props) => {
  const [tab, setTab] = useState("styles")

  const tabConfig = [
    { name: "styles", display: "Styles" },
    { name: "font", display: "Font" },
    { name: "animate", display: "Animate" },
  ]

  return (
    <Popover>
      <PopoverTrigger>
        <TooltipIcon
          tooltip="Style"
          side="bottom"
          container={sectionElement.id as string}
          icon="faPalette"
          iconWrapperClassName="btn btn-small btn-white first-child"
        />
      </PopoverTrigger>
      <PopoverContent sideOffset={5} align="start" alignOffset={-40}>
        <PopoverArrow />
        <div className="w-72 border-solid border border-gray-200 rounded-xl bg-white drop-shadow z-30">
          <div className="flex justify-center gap-4 border-b border-gray-200 pt-2">
            {tabConfig.map(({ name, display }) => {
              return (
                <button
                  key={name}
                  className={`
                    py-1 px-3 font-semibold text-gray-800
                    ${name === tab ? "border-b-4 border-blue-400" : "border-b-4 border-transparent"}
                  hover:bg-gray-100 rounded-t-lg
                  `}
                  onClick={setTab.bind(setTab, name)}
                >
                  {display}
                </button>
              )
            })}
          </div>
          <div className="p-4">
            {tab === "styles" && <StyleOptions sectionElement={sectionElement} />}
            {tab === "font" && <FontOptions sectionElement={sectionElement} />}
            {tab === "animate" && <AnimateOptions sectionElement={sectionElement} />}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}

export default StyleMenu

import React, { useState } from "react";
import { withRef } from '@udecode/cn';
import { findNode } from "@udecode/plate-common";
import { isEditorReadOnly, PlateElement } from "@udecode/plate-common/react";
import { useFocused, useSelected } from 'slate-react';

import { Popover, PopoverContent, PopoverTrigger } from "../../shared/Popover/Popover";
import { PlateHrElement } from "../../plate-config/Plugins/HrLine/HrLine.plugin";
import HrColorSelector from "./HrColorSelector/HrColorSelector";
import { colorClassToRGB } from "../../utils/color.util";
import { useAppSelector } from "../../store/hooks/redux-hooks";


export const HrElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const block = element as PlateHrElement

    const selected = useSelected();
    const focused = useFocused();
    const isReadOnly = isEditorReadOnly(editor);
    const { activePalette } = useAppSelector(state => state.page_colors)

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const onColorChange = (color: string) =>
      editor.setNodes({ color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const nodePath = findNode(editor, { at: [], match: { id: block.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === block.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        className={`
        ${className} 
        page-block divider-block
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        `}
        contentEditable={false}
        suppressContentEditableWarning
        data-id={block.id}
        {...props}
      >
        <Popover open={!isReadOnly ? isPopoverOpen : false} onOpenChange={(open) => setIsPopoverOpen(open)}  >
          <PopoverTrigger asChild>
            <hr
              style={{ backgroundColor: colorClassToRGB(block.color, 100, activePalette.colors)! }}
              className={`h-[2px] w-full rounded-sm border-none ${isReadOnly ? '' : 'cursor-pointer'} ${(selected && focused) ? 'ring-2 ring-blue-400 ring-offset-2' : ""}`}
            />
          </PopoverTrigger>
          <PopoverContent
            onOpenAutoFocus={e => e.preventDefault()}
            onCloseAutoFocus={e => e.preventDefault()}
            side='top'
            sideOffset={10}
            align='center'
            className="flex p-1 w-auto border-solid border border-gray-200 rounded-xl bg-white drop-shadow"
          >
            <HrColorSelector hrColor={block.color} onHrColorChange={onColorChange} hrId={block.id} />
          </PopoverContent>
        </Popover>
        {children}
      </PlateElement>
    );
  }
);

export default HrElement

import React from 'react';
import { withRef } from '@udecode/cn';
import { useEditorRef, useMarkToolbarButton, useMarkToolbarButtonState } from '@udecode/plate-common/react';
import { getMarks } from '@udecode/plate-common';

import { ToolbarButton } from '../../../../shared/Toolbar/plate-ui-toolbar';

export const MarkToolbarButton = withRef<
  typeof ToolbarButton,
  {
    nodeType: string;
    clear?: string | string[];
  }
>(({ clear, nodeType, ...rest }, ref) => {
  const state = useMarkToolbarButtonState({ clear, nodeType });
  const { props } = useMarkToolbarButton(state);
  const editor = useEditorRef()
  const marks = getMarks(editor)

  const isBold = !!marks?.bold && state.nodeType === 'bold'
  const isItalic = !!marks?.italic && state.nodeType === 'italic'
  const isUnderline = !!marks?.underline && state.nodeType === 'underline'
  const isStrikethrough = !!marks?.strikethrough && state.nodeType === 'strikethrough'

  const isAnyMarkActive = isBold || isItalic || isUnderline || isStrikethrough

  return (
    <ToolbarButton
      ref={ref}
      {...props}
      {...rest}
      className={`w-8 h-8 p-0 flex items-center justify-center ${isAnyMarkActive ? 'bg-blue-100 hover:bg-blue-200' : ''}`}
    />
  );
});

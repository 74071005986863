import React, { useState, useEffect } from "react";
import { findNode } from "@udecode/plate-common";
import { isEditorReadOnly, PlateElement } from "@udecode/plate-common/react";
import { withRef } from '@udecode/cn';
import { useSelected } from "slate-react";

import VideoSourceSelector from "./VideoSourceSelector";
import { PlateVideoElement } from "../../plate-config/Plugins/Video/Video.plugin";
import IconButton from '../../shared/IconButton/IconButton';
import TooltipIcon from "../../shared/ToolTip/TooltipIcon";
import { selectedBlockClasses } from "../../utils/color.util";

export const VideoVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const isReadOnly = isEditorReadOnly(editor);
    const block = element as PlateVideoElement;
    const selected = useSelected()

    const [link, setLink] = useState('');
    const [error, setError] = useState<null | string>(null);
    const [isEditing, setIsEditing] = useState(!block.url);

    const nodePath = findNode(editor, { at: [], match: { id: block.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === block.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === block.id;

    useEffect(() => {
      if (block?.url) {
        setLink(block.url);
        setIsEditing(false);
      }
    }, [block]);

    const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
      setLink(e.target.value);
      setError(null);
    };

    const onConfirm = () => {
      if (validateVideoUrl(link)) {
        onEditorStateUpdate(link);
        setIsEditing(false);
      }
    };

    const onEditorStateUpdate = (url: string) =>
      editor.setNodes({ url } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id });

    const validateVideoUrl = (url: string) => {
      // Implement your video URL validation logic here
      if (url.trim() === '') {
        setError('Please enter a valid video URL');
        return false;
      }
      setError(null);
      return true;
    };

    const onElementDeleteHandle = () => editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })

    if (isReadOnly) return block.url
      ?
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        data-id={block.id}
        element={element}
        className={`
        ${className}
         page-block video-block
         ${isFirstChild ? 'first-section-child' : ""} 
         ${isLastChild ? 'last-section-child' : ""}
         `}
        contentEditable={false}
        suppressContentEditableWarning
        {...props}
      >
        <VideoSourceSelector link={block.url} />
        {children}
      </PlateElement>
      : null;

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        data-id={block.id}
        element={element}
        className={`
        ${className}
         page-block video-block
         ${isFirstChild ? 'first-section-child' : ""} 
         ${isLastChild ? 'last-section-child' : ""}
        ${selected ? selectedBlockClasses : ""}
        ${selected && !isEditing ? 'pt-[20px] pb-[1px] ' : ""}
         `}
        data-plate-selectable
        contentEditable={false}
        suppressContentEditableWarning
        {...props}
      >
        {!isEditing && block.url ? (
          <div className="relative">
            {selected && <div className="absolute -top-7 right-0">
              <button
                onClick={setIsEditing.bind(setIsEditing, true)}
                className="btn btn-xs btn-white">Edit Video</button>
            </div>}
            <VideoSourceSelector link={block.url} />
          </div>
        ) : (
          <div className="w-full min-h-96 bg-gray-200/80 border-gray-400 rounded-xl shadow-sm text-center p-8 pt-[37px]">
            <TooltipIcon
              tooltip="Delete"
              icon="faTrash"
              iconWrapperClassName="btn-small rounded-lg hover:bg-red-100 absolute text-gray-800 right-1 top-1"
              side="top"
              container={block.id}
              onClick={onElementDeleteHandle}
            />
            <div className={`form-group mb-3 ${error ? "error" : ""}`}>
              <div className="flex gap-2">
                <input
                  type="text"
                  className="form-control flex-grow w-full text-xs sm:text-xs md:text-base text-gray-700"
                  placeholder="Insert video link"
                  onChange={onInputChanged}
                  value={link}
                />
                <IconButton
                  btnClassName='btn btn-primary only-child'
                  icon='faCheck'
                  onClick={onConfirm}
                />
              </div>
              {error && <div className="form-hint text-left error">{error}</div>}
            </div>
            <div className="flex items-center justify-center">
              <div className="text-gray-800">
                <h3 className="mb-2">Upload a video from:</h3>
                <ul className="list-none text-base">
                  {['Youtube', 'Vimeo', 'Loom', 'Vidyard', 'Facebook', 'Wistia', 'Twitch', 'Streamable', 'Dailymotion', 'Kaltura'].map((provider, i) => (
                    <li key={i} className="pb-2">{provider}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {children}
      </PlateElement>
    );
  }
);

export default VideoVoidElement;

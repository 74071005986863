import React from "react"

import { textColorFromHex } from "../../utils/color.util"
import Icon from "../Icon/Icon"
import { useAppSelector } from "../../store/hooks/redux-hooks"


type Props = {
  color: string
  setColor: (color: string) => void
  transparent?: boolean
  variant?: 'default' | 'small'
}

const ColorSelector = ({ color, setColor, transparent = false, variant = 'default' }: Props) => {
  const { activePalette } = useAppSelector(state => state.page_colors)
  const colors = [...activePalette.colors, "#000000", "#ffffff", ...(transparent ? ["transparent"] : [])]

  const isColorActive = (backgroundColor: string, index: number): boolean => {
    switch (true) {
      case backgroundColor === color || `color-${index}` === color:
        return true;
      case backgroundColor === "#000000" && color === "color-black":
        return true;
      case backgroundColor === "#ffffff" && color === "color-white":
        return true;
      case backgroundColor === "transparent" && color === "color-transparent":
        return true;
      default:
        return false;
    }
  }

  const onColorChangeHandle = (color: string, position: number) => {
    let colorClass
    switch (color) {
      case "#000000":
        colorClass = "color-black"
        break;
      case "#ffffff":
        colorClass = "color-white"
        break;
      case "transparent":
        colorClass = "color-transparent"
        break;
      default:
        colorClass = `color-${position}`
        break;
    }
    setColor(colorClass)
  }
  return (
    <div className="w-full">
      <div className="flex flex-wrap text-center gap-2 mt-1">
        {colors.map((backgroundColor, index) =>
          <React.Fragment key={`${backgroundColor}-button-color`} >
            {backgroundColor === "transparent" ?
              (
                <button
                  onClick={onColorChangeHandle.bind(onColorChangeHandle, backgroundColor, index)}
                  className={`
                  ${variant === 'small' ? 'h-8 w-8' : 'h-10 w-10'}
                  rounded-full border border-gray-300 hover:ring-gray-200 hover:ring transparent-background-selector
                  `}
                >
                  {isColorActive(backgroundColor, index) && <Icon icon="faCheck" />}
                </button>
              ) : (
                <button
                  onClick={onColorChangeHandle.bind(onColorChangeHandle, backgroundColor, index)}
                  className={`
                  ${variant === 'small' ? 'h-8 w-8' : 'h-10 w-10'}
                  rounded-full border border-gray-300 hover:ring-gray-200 hover:ring
                  `}
                  style={{ backgroundColor: backgroundColor, color: textColorFromHex(backgroundColor) }}
                >
                  {isColorActive(backgroundColor, index) && <Icon icon="faCheck" />}
                </button>
              )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default ColorSelector

import React from 'react';
import { withProps } from '@udecode/cn';
import { v4 as uuidv4 } from 'uuid';
import { HeadingPlugin } from '@udecode/plate-heading/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { BulletedListPlugin, ListItemPlugin, ListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { LinkPlugin } from '@udecode/plate-link/react';
import { SlashPlugin, SlashInputPlugin } from "@udecode/plate-slash-command/react"
import { createPlateEditor, ParagraphPlugin, PlateElement, PlateLeaf } from '@udecode/plate-common/react';
import { MentionInputPlugin, MentionPlugin } from '@udecode/plate-mention/react';
import { BoldPlugin, ItalicPlugin, StrikethroughPlugin, UnderlinePlugin } from '@udecode/plate-basic-marks/react';
import { AlignPlugin } from '@udecode/plate-alignment';
import { NodeIdPlugin } from '@udecode/plate-node-id';
import { DndPlugin } from '@udecode/plate-dnd';
import { AutoformatPlugin } from '@udecode/plate-autoformat/react';
import { ExitBreakPlugin, SoftBreakPlugin } from '@udecode/plate-break/react';
import { ResetNodePlugin } from '@udecode/plate-reset-node/react';
import { SelectOnBackspacePlugin } from '@udecode/plate-select';
import { DocxPlugin } from '@udecode/plate-docx';
import { MarkdownPlugin } from '@udecode/plate-markdown';
import { JuicePlugin } from '@udecode/plate-juice';
import { TElement } from '@udecode/plate-common';
import { HtmlReactPlugin } from '@udecode/plate-html/react';
import { BlockSelectionPlugin } from '@udecode/plate-selection/react';

import { SectionPlugin } from './Plugins/Section/Section.plugin';
import { EmbedPlugin } from './Plugins/Embed/Embed.plugin';
import { VideoPlugin } from './Plugins/Video/Video.plugin';
import { HorizontalRulePlugin } from './Plugins/HrLine/HrLine.plugin';
import { ButtonGroupPlugin } from './Plugins/Button/ButtonGroup.plugin';
import { ButtonPlugin } from './Plugins/Button/Button.plugin';
import { ImagePlugin } from './Plugins/Image/Image.plugin';
import { PricingPlugin } from './Plugins/Pricing/Pricing.plugin';
import { RecordingPlugin } from './Plugins/Recording/Recording.plugin';
import { AiTextBlockPlugin } from './Plugins/AiTextBlock/AiTextBlock.plugin';
import { AssessmentPlugin } from './Plugins/Assessment/Assessment.plugin';
import { ColumnGroupPlugin } from './Plugins/ColumnGroup/ColumnGroup.plugin';
import { ColumnPlugin } from './Plugins/ColumnGroup/Column.plugin';
import { TemplateVariableInputPlugin, TemplateVariablePlugin } from './Plugins/TemplateVariableCommand/TemplateVariableCommand.plugin';
import { EmojiInputPlugin, EmojiPlugin } from './Plugins/EmojiCommand/EmojiCommand.plugin';
import { TextColorLeafPlugin } from './Plugins/TextColor/TextColor.plugin';
import { TextHighlightLeafPlugin } from './Plugins/TextHighlight/TextHighlight.plugin';
import { TextSizeLeafPlugin } from './Plugins/TextSize/TextSize.plugin';
import { autoformatRules } from './Plugins/Autoformat/AutoformatRules.plugin';

import { BlockquoteElement } from '../plate-components/Blockquote/blockquote-element';
import { HeadingElement } from '../plate-components/Heading/heading-element';
import { ParagraphElement } from '../plate-components/Paragraph/paragraph-element';
import { withPlaceholders } from '../plate-components/Placeholder/Placeholder';
import { withDraggables } from './Plugins/DnD/with-draggables';
import EmbedVoidElement from '../plate-components/Embed/EmbedElement';
import { HrElement } from '../plate-components/HorizontalRule/HorizontalRuleElement';
import SectionElement from '../plate-components/Section/SectionElement';
import { ListElement } from '../plate-components/List/ListElement';
import VideoVoidElement from '../plate-components/Video/VideoElement';
import { TextHighlightLeaf } from '../plate-components/TextHighlightLeaf/TextHighlightLeaf';
import { TextColorLeaf } from '../plate-components/TextColorLeaf/TextColorLeaf';
import { LinkFloatingToolbar } from '../plate-components/Link/LinkFloatingToolbar/LinkFloatingToolbar';
import { LinkElement } from '../plate-components/Link/LinkElement';
import ButtonGroupElement from '../plate-components/Button/ButtonGroupElement';
import ButtonVoidElement from '../plate-components/Button/ButtonElement';
import ImageVoidElement from '../plate-components/Image/ImageElement';
import PricingVoidElement from '../plate-components/Pricing/PricingElement';
import RecordingVoidElement from '../plate-components/Recording/RecordingElement';
import { TextSizeLeaf } from '../plate-components/TextSizeLeaf/TextSizeLeaf';
import AiTextBlockVoidElement from '../plate-components/AiTextBlock/AiTextBlock';
import AssessmentVoidElement from '../plate-components/Assessment/AssessmentElement';
import { MentionElement } from '../plate-components/Mention/MentionElement';
import { MentionInputElement } from '../plate-components/Mention/MentionInputElement';
import { SlashInputElement } from '../plate-components/SlashCommand/SlashInputElement';
import { EmojiInputElement } from '../plate-components/EmojiCommand/EmojiInputElement';
import { ColumnGroupElement } from '../plate-components/ColumnGroup/ColumnGroup';
import { ColumnElement } from '../plate-components/ColumnGroup/Column';
import { TemplateVariableInputElement } from '../plate-components/TemplateVariableCommand/TemplateVariableInputElement';
import TemplateVariableElement from '../plate-components/TemplateVariableCommand/TemplateVariableElement';



const useAppEditor = (isTemplate: boolean, initialValue: TElement[]) => {
  const editor = createPlateEditor({
    // CRITICAL NOTE: the order of the plugins is important
    plugins: [
      // -------------------- Serialization --------------------
      HtmlReactPlugin,
      // -------------------- Serialization --------------------

      // -------------------- Nodes --------------------
      SectionPlugin,
      ParagraphPlugin,
      HeadingPlugin,
      BlockquotePlugin,
      ListPlugin,
      LinkPlugin.configure({
        render: { afterEditable: () => <LinkFloatingToolbar /> },
      }),
      MentionPlugin,
      MentionInputPlugin,
      EmbedPlugin,
      VideoPlugin,
      HorizontalRulePlugin,
      ButtonGroupPlugin,
      ButtonPlugin,
      ImagePlugin,
      PricingPlugin,
      RecordingPlugin,
      AssessmentPlugin,
      ColumnGroupPlugin,
      ColumnPlugin,
      EmojiInputPlugin,
      EmojiPlugin,
      SlashPlugin,
      SlashInputPlugin,
      ...(isTemplate ? [AiTextBlockPlugin] : []),
      ...(isTemplate ? [TemplateVariablePlugin] : []),
      // -------------------- Nodes --------------------

      // -------------------- Marks --------------------
      BoldPlugin,
      ItalicPlugin,
      UnderlinePlugin,
      StrikethroughPlugin,
      AlignPlugin.configure({
        inject: {
          targetPlugins: [
            ParagraphPlugin.key,
            HEADING_KEYS.h1,
            HEADING_KEYS.h2,
            BlockquotePlugin.key,
            ListItemPlugin.key,
          ],
        },
      }),
      TextColorLeafPlugin,
      TextHighlightLeafPlugin,
      TextSizeLeafPlugin,
      // -------------------- Marks --------------------

      // -------------------- Autoformat plugins --------------------
      AutoformatPlugin.configure({
        options: {
          rules: autoformatRules,
          enableUndoOnDelete: true,
        },
      }),
      ExitBreakPlugin.configure({
        options: {
          rules: [
            {
              hotkey: 'enter',
              query: {
                end: true,
                allow: [HEADING_KEYS.h1, HEADING_KEYS.h2, BlockquotePlugin.key],
              },
              relative: true,
              level: 1,
            },
          ],
        },
      }),
      SoftBreakPlugin.configure({
        options: {
          rules: [
            {
              hotkey: 'shift+enter',
              query: {
                allow: [HEADING_KEYS.h1, HEADING_KEYS.h2, BlockquotePlugin.key, ParagraphPlugin.key],
              },
            },
          ],
        },
      }),
      ResetNodePlugin.configure({
        options: {
          disableFirstBlockReset: true,
        },
      }),
      SelectOnBackspacePlugin.configure({
        options: {
          query: {
            allow: [
              AiTextBlockPlugin.key, AssessmentPlugin.key, ButtonGroupPlugin.key,
              EmbedPlugin.key, HorizontalRulePlugin.key, ImagePlugin.key, PricingPlugin.key, VideoPlugin.key
            ]
          },
          removeNodeIfEmpty: true,
        },
      }),
      // -------------------- Autoformat plugins --------------------

      // -------------------- Core plugins --------------------
      NodeIdPlugin.configure({
        options: {
          idCreator: () => uuidv4(),
          disableInsertOverrides: true
        }
      }),
      DndPlugin.configure({
        options: { enableScroller: true },
      }),
      BlockSelectionPlugin,
      // -------------------- Core plugins --------------------

      // --------------------  Deserialization --------------------
      DocxPlugin,
      MarkdownPlugin,
      JuicePlugin,
      // --------------------  Deserialization --------------------
    ],
    override: {
      components: withDraggables(
        withPlaceholders({
          [SectionPlugin.key]: SectionElement,
          [EmbedPlugin.key]: EmbedVoidElement,
          [VideoPlugin.key]: VideoVoidElement,
          [BlockquotePlugin.key]: BlockquoteElement,
          [HEADING_KEYS.h1]: withProps(HeadingElement, { variant: 'h1' }),
          [HEADING_KEYS.h2]: withProps(HeadingElement, { variant: 'h2' }),
          [ParagraphPlugin.key]: ParagraphElement,
          [BulletedListPlugin.key]: withProps(ListElement, { variant: 'ul' }),
          [NumberedListPlugin.key]: withProps(ListElement, { variant: 'ol' }),
          [ListItemPlugin.key]: withProps(PlateElement, { as: 'li' }),
          [LinkPlugin.key]: LinkElement,
          [ButtonGroupPlugin.key]: ButtonGroupElement,
          [ButtonPlugin.key]: ButtonVoidElement,
          [ImagePlugin.key]: ImageVoidElement,
          [AssessmentPlugin.key]: AssessmentVoidElement,
          [PricingPlugin.key]: PricingVoidElement,
          [HorizontalRulePlugin.key]: HrElement,
          [ColumnGroupPlugin.key]: ColumnGroupElement,
          [ColumnPlugin.key]: ColumnElement,
          [RecordingPlugin.key]: RecordingVoidElement,
          ...isTemplate && ({ [AiTextBlockPlugin.key]: AiTextBlockVoidElement }),
          [BoldPlugin.key]: withProps(PlateLeaf, { as: 'strong' }),
          [ItalicPlugin.key]: withProps(PlateLeaf, { as: 'em' }),
          [StrikethroughPlugin.key]: withProps(PlateLeaf, { as: 's' }),
          [UnderlinePlugin.key]: withProps(PlateLeaf, { as: 'u' }),
          [TextColorLeafPlugin.key]: TextColorLeaf,
          [TextHighlightLeafPlugin.key]: TextHighlightLeaf,
          [TextSizeLeafPlugin.key]: TextSizeLeaf,
          [MentionPlugin.key]: MentionElement,
          [MentionInputPlugin.key]: MentionInputElement,
          [SlashInputPlugin.key]: SlashInputElement,
          [EmojiInputPlugin.key]: EmojiInputElement,
          ...isTemplate && ({
            [TemplateVariableInputPlugin.key]: TemplateVariableInputElement,
            [TemplateVariablePlugin.key]: TemplateVariableElement,
          }),
        }))
    },
    shouldNormalizeEditor: true,
    value: initialValue,
  })
  return editor;
}

export default useAppEditor;
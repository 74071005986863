import React from 'react';

const ModalSpinner = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-300">
      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" style={{ borderTopColor: 'transparent' }} />
    </div>
  );
}

export default ModalSpinner;

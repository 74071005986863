import React, { useRef, useState } from "react";
import { PlateRenderElementProps } from "@udecode/plate-common/react";
import ReactPlayer from 'react-player/lazy'
import { OnProgressProps } from "react-player/base";

import { PlateRecordingElement } from "../../../components/plate-config/Plugins/Recording/Recording.plugin";
import RecordingTranscript from "./RecordingTranscript";
import { useAppSelector } from "../../store/hooks/redux-hooks";



const RecordingVoidElement = ({
  attributes,
  children,
  element,
}: PlateRenderElementProps) => {
  const block = element as PlateRecordingElement
  const { conversation, recording_file, recording_kind } = block

  const playerRef = useRef<ReactPlayer>(null)
  const [playbackTime, setPlaybackTime] = useState(0)
  const { isMobilePreviewActive } = useAppSelector(state => state.page_addendums)


  const handleProgress = (state: OnProgressProps) => setPlaybackTime(state.playedSeconds)

  const handleUtteranceClick = (time: number) => {
    if (playerRef.current) {
      playerRef.current.seekTo(time);
    }
  };

  const wrapperStyles = `flex flex-col gap-4 ${isMobilePreviewActive ? '' : 'lg:flex-row'}`

  return (
    <div
      data-id={block.id}
      className="my-2"
      contentEditable={false}
      suppressContentEditableWarning={true}
      {...attributes}
    >
      <div className={wrapperStyles}>
        <ReactPlayer
          ref={playerRef}
          url={recording_file}
          controls={true}
          height={recording_kind === "audio" ? "54px" : "360px"}
          onProgress={handleProgress}
          width={"100%"}
          style={{ minWidth: '50%' }}
        />
        {!!conversation.length &&
          <RecordingTranscript
            conversation={conversation}
            playbackTime={playbackTime}
            handleUtteranceClick={handleUtteranceClick}
          />}
      </div>
      {children}
    </div>
  )
}

export default RecordingVoidElement

import React from 'react';
import { cn, withRef } from '@udecode/cn';
import { PortalBody, useComposedRef, useEditorRef } from '@udecode/plate-common/react';
import {
  flip,
  FloatingToolbarState,
  offset,
  useFloatingToolbar,
  useFloatingToolbarState,
} from '@udecode/plate-floating';

import { Toolbar } from '../../shared/Toolbar/plate-ui-toolbar';


export const FloatingToolbar = withRef<
  typeof Toolbar,
  {
    portalElement?: Element
    state?: FloatingToolbarState;
  }
>(({ state, portalElement, children, ...props }, componentRef) => {
  const editor = useEditorRef();
  const floatingToolbarState = useFloatingToolbarState({
    editorId: editor.id,
    focusedEditorId: editor.id,
    ...state,
    floatingOptions: {
      placement: 'top',
      middleware: [
        offset(12),
        flip({
          padding: 12,
          fallbackPlacements: [
            'top-start',
            'top-end',
            'bottom-start',
            'bottom-end',
          ],
        }),
      ],
      ...state?.floatingOptions,
    },
  });

  const {
    ref: floatingRef,
    props: rootProps,
    hidden,
  } = useFloatingToolbar(floatingToolbarState);

  const ref = useComposedRef<HTMLDivElement>(componentRef, floatingRef);

  if (hidden) return null;

  return (
    <PortalBody element={portalElement}>
      <Toolbar
        ref={ref}
        className={cn(
          'absolute z-50 whitespace-nowrap border bg-popover px-3 opacity-100 shadow-sm drop-shadow print:hidden text-gray-900 bg-gray-100 rounded-xl'
        )}
        {...rootProps}
        {...props}
      >
        {children}
      </Toolbar>
    </PortalBody>
  );
});
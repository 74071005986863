import { createPlatePlugin, PlateEditor } from "@udecode/plate-common/react";
import { findNode, TElement, TNodeEntry } from "@udecode/plate-common";

import { createDefaultImageElement } from "../Image/Image.plugin";
import { createDefaultH2Element, createDefaultParagraphElement } from "../DefaultMockups/DefaultMockups";
import { ColumnPlugin, createDefaultColumnElement } from './Column.plugin';


const extendEditor = ({ editor }) => {
  const { normalizeNode } = editor as PlateEditor;
  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    // Children control - only columns allowed
    if (node.type === ColumnGroupPlugin.key) {
      const columnGroup = node as PlateColumnGroupElement;
      columnGroup.children.map((child) => {
        if (child.type !== ColumnPlugin.key) {
          editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
        }
      });
    }
    // Children amount normalization - ensure that column group always has at least 2 children
    if (node.type === ColumnGroupPlugin.key) {
      const columnGroup = node as PlateColumnGroupElement;
      if (columnGroup.children.length < 2) {
        const path = findNode(editor, { at: [], match: (n: any) => n.id === columnGroup.id })!;
        editor.insertNodes(createDefaultColumnElement(), { at: [...path[1], +columnGroup.children.length] });
      }
      // Children amount normalization - ensure that column group always has at most 4 children
      if (columnGroup.children.length > 4) {
        const path = findNode(editor, { at: [], match: (n: any) => n.id === columnGroup.id })!;
        editor.removeNodes({ at: [...path[1], 4] });
      }
    }
    // Children amount normalization - ensure that column group always has at most 4 children
    if (node.type === ColumnGroupPlugin.key) {
      const columnGroup = node as PlateColumnGroupElement;
      if (columnGroup.children.length > 4) {
        const path = findNode(editor, { at: [], match: (n: any) => n.id === columnGroup.id })!;
        editor.removeNodes({ at: [...path[1], 4] });
      }
    }
    normalizeNode([node, path]);
  };

  return editor;
};

export const ColumnGroupPlugin = createPlatePlugin({
  key: 'column_group',
  node: {
    type: 'column_group',
    isElement: true,
  },
  extendEditor: extendEditor,
  priority: 900
});

export interface PlateColumnGroupElement extends TElement {
  id: string
  type: typeof ColumnGroupPlugin.key,
  style: string | null,
}

export const createDefaultColumnGroupElement = (): TElement => ({
  type: ColumnGroupPlugin.key,
  children: [createDefaultColumnElement(), createDefaultColumnElement()],
})

export const createDefaultThreeColumnGroupElement = (): TElement => ({
  type: ColumnGroupPlugin.key, children: [
    { type: ColumnPlugin.key, width: 33, children: [createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 33, children: [createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 33, children: [createDefaultParagraphElement()] },
  ]
})

export const createDefaultFourColumnGroupElement = (): TElement => ({
  type: ColumnGroupPlugin.key, children: [
    { type: ColumnPlugin.key, width: 25, children: [createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 25, children: [createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 25, children: [createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 25, children: [createDefaultParagraphElement()] },
  ]
})

export const createTwoColumnsWithImageAtLeftElement = (): TElement => ({
  type: ColumnGroupPlugin.key, children: [
    { type: ColumnPlugin.key, width: 50, children: [createDefaultImageElement(), createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 50, children: [createDefaultParagraphElement()] },
  ]
})

export const createTwoColumnsWithImageAtRightElement = (): TElement => ({
  type: ColumnGroupPlugin.key, children: [
    { type: ColumnPlugin.key, width: 50, children: [createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 50, children: [createDefaultImageElement(), createDefaultParagraphElement()] },
  ]
})

export const createThreeColumnsWithImagesElement = (): TElement => ({
  type: ColumnGroupPlugin.key, style: 'wide', children: [
    { type: ColumnPlugin.key, width: 33, children: [createDefaultImageElement(), createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 33, children: [createDefaultImageElement(), createDefaultParagraphElement()] },
    { type: ColumnPlugin.key, width: 33, children: [createDefaultImageElement(), createDefaultParagraphElement()] },
  ]
})

export const createFourColumnsWithContentElement = (): TElement => ({
  type: ColumnGroupPlugin.key, style: 'wide', children: [
    {
      type: ColumnPlugin.key, width: 25, children: [createDefaultH2Element(), createDefaultImageElement(), createDefaultParagraphElement()]
    },
    {
      type: ColumnPlugin.key, width: 25, children: [createDefaultH2Element(), createDefaultImageElement(), createDefaultParagraphElement()]
    },
    {
      type: ColumnPlugin.key, width: 25, children: [createDefaultH2Element(), createDefaultImageElement(), createDefaultParagraphElement()]
    },
    {
      type: ColumnPlugin.key, width: 25, children: [createDefaultH2Element(), createDefaultImageElement(), createDefaultParagraphElement()]
    },
  ]
})
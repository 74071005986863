import React, { FC } from 'react';
import {
  WithDraggableOptions,
  withDraggable as withDraggablePrimitive,
} from '@udecode/plate-dnd';
import { createNodesWithHOC, ParagraphPlugin } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';

import { HorizontalRulePlugin } from '../HrLine/HrLine.plugin';
import { ButtonGroupPlugin } from '../Button/ButtonGroup.plugin';
import { VideoPlugin } from '../Video/Video.plugin';
import { ImagePlugin } from '../Image/Image.plugin';
import { EmbedPlugin } from '../Embed/Embed.plugin';
import { AssessmentPlugin } from '../Assessment/Assessment.plugin';
import { PricingPlugin } from '../Pricing/Pricing.plugin';
import { ColumnGroupPlugin } from '../ColumnGroup/ColumnGroup.plugin';
import { Draggable, DraggableProps } from '../../../plate-components/DnD/draggable';
import { AiTextBlockPlugin } from '../AiTextBlock/AiTextBlock.plugin';


export const withDraggable = (
  Component: FC,
  options?: WithDraggableOptions<
    Partial<Omit<DraggableProps, 'editor' | 'element' | 'children'>>
  >
) =>
  withDraggablePrimitive<DraggableProps>(Draggable, Component, options as any);

export const withDraggablesPrimitive = createNodesWithHOC(withDraggable);

export const withDraggables = (components: any) => {

  return withDraggablesPrimitive(components, [
    {
      keys: [
        ParagraphPlugin.key,
        HEADING_KEYS.h1,
        HEADING_KEYS.h2,
        BlockquotePlugin.key,
        HorizontalRulePlugin.key,
        NumberedListPlugin.key,
        BulletedListPlugin.key,
        ButtonGroupPlugin.key,
        VideoPlugin.key,
        ImagePlugin.key,
        EmbedPlugin.key,
        AssessmentPlugin.key,
        PricingPlugin.key,
        ColumnGroupPlugin.key,
        AiTextBlockPlugin.key
      ],
      // Documentation: Level prop is responsible of the DnD depth of the block, it allow to drag and drop the block inside another block
      level: null,
    },
    {
      keys: [ParagraphPlugin.key],
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[4px] left-[-8px]',
        },
      },
    },
    {
      key: HEADING_KEYS.h1,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[12px] left-[-8px]',
        },
      },
    },
    {
      key: HEADING_KEYS.h2,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[4px] left-[-8px]',
        },
      },
    },
    {
      key: BlockquotePlugin.key,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[2px] left-[-8px]',
        },
      },
    },
    {
      key: HorizontalRulePlugin.key,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[-11px] left-[-8px]',
        },
      },
    },
    {
      keys: [BulletedListPlugin.key, NumberedListPlugin.key],
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[7px] left-[-8px]',
        },
      },
    },
    {
      key: ButtonGroupPlugin.key,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[30px] left-[-7px]',
        },
      },
    },
    {
      keys: [ImagePlugin.key, VideoPlugin.key, EmbedPlugin.key],
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[0px] left-[-8px]',
        },
      },
    },
    {
      key: AssessmentPlugin.key,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[0px] left-[-12px]',
        },
      },
    },
    {
      key: PricingPlugin.key,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[4px] left-[-8px]',
        },
      },
    },
    {
      key: ColumnGroupPlugin.key,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[7px] left-[-26px]',
        },
      },
    },
    {
      key: AiTextBlockPlugin.key,
      draggableProps: {
        classNames: {
          gutterLeft: 'top-[0px] left-[-10px]',
        },
      },
    },
  ]);
};

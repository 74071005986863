import React, { useState } from 'react';
import { select, getNodeParent } from '@udecode/plate-common';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';
import { setAlign, Alignment, } from '@udecode/plate-alignment';
import { ListItemContentPlugin } from '@udecode/plate-list/react';
import { getListItemEntry } from '@udecode/plate-list';

import Icon from '../../../../shared/Icon/Icon';
import ButtonSelector from '../../../../shared/ButtonSelector/ButtonSelector';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../shared/Popover/Popover';
import { ToolbarButton } from '../../../../shared/Toolbar/plate-ui-toolbar';
import { FONT_ALIGNMENT_OPTIONS } from '../../../Section/section.config';


export function AlignDropdownMenu() {
  const editor = useEditorRef();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const onAlignChange = (align: string) => {
    setAlign(editor, { value: align as Alignment });
    select(editor, editor.selection!);
    focusEditor(editor);
    setPopoverOpen(false);
  };
  const nodeParent = editor.selection?.anchor?.path ? getNodeParent(editor, editor.selection?.anchor.path) : null;
  const nodeParentTypeCheck = !!nodeParent
    ? nodeParent.type === ListItemContentPlugin.key
      ? getListItemEntry(editor, { at: editor.selection?.anchor?.path })?.listItem[0]
      : nodeParent
    : null;

  const currentAlign = nodeParentTypeCheck ? nodeParentTypeCheck.align ? nodeParentTypeCheck.align as string : 'left' : 'left';
  const alignIconOptions = [
    { name: 'left', icon: 'faAlignLeft' },
    { name: 'center', icon: 'faAlignCenter' },
    { name: 'right', icon: 'faAlignRight' },
  ];
  const alignIcon = alignIconOptions.find((option) => option.name === currentAlign);

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <ToolbarButton tooltip="Align" pressed={popoverOpen} isDropdown={true}>
          <Icon icon={alignIcon ? alignIcon.icon : 'faAlignLeft'} />
        </ToolbarButton>
      </PopoverTrigger>
      <PopoverContent align="center" sideOffset={5}>
        <div className="mt-2">
          <ButtonSelector
            data={FONT_ALIGNMENT_OPTIONS}
            current={alignIcon ? currentAlign : 'left'}
            setCurrent={onAlignChange}
            wrapperClassName="ring-4 ring-white"
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export const textColorFromHex = (hex: string) => {
  return lightOrDarkColor(hex) === "light" ? "black" : "white";
}

export const lightOrDarkColor = (hex: string | null) => {
  if (!hex) {
    return "light"
  }
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 186) ? 'light' : 'dark';
}

export function getColorHexFromClass(color: string | null, colors: string[]) {
  if (!color) return null
  if (color === "color-black") return "#000000"
  if (color === "color-white") return "#ffffff"
  if (color.startsWith("#")) return color

  const myColors = colors?.length > 0 ? colors : []
  return myColors[+color.split("-")[1]]
}

export function colorClassToRGB(color: string, alpha: number, colors: string[]) {
  const hex = getColorHexFromClass(color, colors)
  if (hex) {
    return hexToRGB(hex, alpha)
  } else {
    return null
  }
}

export function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (typeof alpha === "undefined") {
    return `rgb(${r}, ${g}, ${b})`
  } else {
    return `rgba(${r}, ${g}, ${b}, ${alpha / 100})`
  }
}

export function textColorFromColorClass(color: string, colors: string[]) {
  const hex = getColorHexFromClass(color, colors)!
  return textColorFromHex(hex)
}


export const selectedBlockClasses = 'ring-2 ring-blue-400 ring-offset-1 rounded-xl'
import React from 'react';
import { withRef, withVariants } from '@udecode/cn';
import { findNode } from '@udecode/plate-common';
import { PlateElement } from '@udecode/plate-common/react';
import { cva } from 'class-variance-authority';

import { colorClassToRGB } from '../../utils/color.util';
import { useAppSelector } from '../../store/hooks/redux-hooks';

const listVariants = cva('m-0 ps-6 list-outside', {
  variants: {
    variant: {
      ul: `slate-ul-wrapper page-block list-block
      list-disc [&_ul]:list-[circle] [&_ul_ul]:list-[square] 
      [&_ul_ul_ul]:list-[disc] [&_ul_ul_ul_ul]:list-[circle] [&_ul_ul_ul_ul_ul]:list-[square]
      [&_ul_ul_ul_ul_ul_ul]:list-[disc] [&_ul_ul_ul_ul_ul_ul_ul]:list-[circle]  [&_ul_ul_ul_ul_ul_ul_ul_ul]:list-[square]
       `,
      ol: `slate-ol-wrapper page-block list-block
        list-decimal [&_ol]:list-[lower-alpha] [&_ol_ol]:list-[lower-roman] 
        [&_ol_ol_ol]:list-decimal [&_ol_ol_ol_ol]:list-[lower-alpha] [&_ol_ol_ol_ol_ol]:list-[lower-roman]
        [&_ol_ol_ol_ol_ol_ol]:list-decimal [&_ol_ol_ol_ol_ol_ol_ol]:list-[lower-alpha]  [&_ol_ol_ol_ol_ol_ol_ol_ol]:list-[lower-roman]
        [&_ol_ol_ol_ol_ol_ol_ol_ol_ol]:list-decimal
     `,
    },
  },
});

const ListElementVariants = withVariants(PlateElement, listVariants, [
  'variant',
]);

export interface ListStyleTypes extends React.CSSProperties {
  '--list-marker-color': string;
}

export const ListElement = withRef<typeof ListElementVariants>(
  ({ children, style, variant = 'ul', className, ...props }, ref) => {
    const Component = variant!;
    const colors = useAppSelector(state => state.page_colors.activePalette.colors)
    const node = findNode(props.editor, { at: [], match: { id: props.element.id } })!
    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
    const section = props.editor.children[node[1][0]]

    const sectionChildren = section.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === props.element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === props.element.id;

    const color = colorClassToRGB(section.font_color as string, 100, colors)

    const listStyles: ListStyleTypes = {
      '--list-marker-color': color as string,
      ...style
    }

    delete listStyles.textAlign;

    return (
      <ListElementVariants
        variant={variant}
        ref={ref}
        data-id={props.element.id}
        asChild
        style={listStyles}
        className={`
        ${className}
        ${isFirstChild ? 'first-section-child' : ""}
        ${isLastChild ? 'last-section-child' : ""}
        `}
        {...props}
      >
        <Component>{children}</Component>
      </ListElementVariants>
    );
  }
);

import React, { useEffect, useState } from 'react';
import { select, setMarks, getMarks, removeMark } from '@udecode/plate-common';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';

import Icon from '../../../../shared/Icon/Icon';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../shared/Popover/Popover';
import PopoverSelectorTitle from '../../../../shared/Popover/PopoverSelectorTitle/PopoverSelectorTitle';
import ButtonSelector from '../../../../shared/ButtonSelector/ButtonSelector';
import { TextColorLeafPlugin } from '../../../../plate-config/Plugins/TextColor/TextColor.plugin';
import { TextHighlightLeafPlugin } from '../../../../plate-config/Plugins/TextHighlight/TextHighlight.plugin';
import { ToolbarButton } from '../../../../shared/Toolbar/plate-ui-toolbar';
import ColorSelector from '../../../../shared/ColorSelector/ColorSelector';
import SystemColorSelector from '../../../../shared/ColorSelector/SystemColorSelector';

const ColorDropdown = () => {
  const editor = useEditorRef()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [type, setType] = useState<string>(TextColorLeafPlugin.key)
  const [color, setColor] = useState('')
  // Get the current marks of selected text
  const marks = getMarks(editor)

  useEffect(() => {
    if (type === TextColorLeafPlugin.key) {
      setColor(marks?.font_color as string || '')
    }
    if (type === TextHighlightLeafPlugin.key) {
      setColor(marks?.text_highlight as string || '')
    }
  }, [type, marks])

  const onColorChange = (color: string) => {
    setColor(color)
    // We need to set the section, or it will be lost when we set the marks, and it will lead to showing tooltip
    select(editor, editor.selection!);
    focusEditor(editor);
    // Set the mark
    setMarks(editor, {
      ...(type === TextColorLeafPlugin.key && { 'font_color': color }),
      ...(type === TextHighlightLeafPlugin.key && { 'text_highlight': color }),
    });
    setPopoverOpen(false)
  }

  const onColorUnset = () => {
    removeMark(editor, { key: type === TextColorLeafPlugin.key ? 'font_color' : 'text_highlight' })
    setPopoverOpen(false)
    select(editor, editor.selection!);
    focusEditor(editor);
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <ToolbarButton tooltip={"Text color.."} pressed={popoverOpen} isDropdown={true}>
          <Icon icon='faPalette' />
        </ToolbarButton>
      </PopoverTrigger>
      <PopoverContent align="start" sideOffset={5}  >
        <div className="border-solid border w-60 border-gray-200 rounded-xl bg-white drop-shadow z-30">
          <div className="p-4">
            <ButtonSelector
              data={[
                { name: TextColorLeafPlugin.key, label: "Text" },
                { name: TextHighlightLeafPlugin.key, label: "Highlight" },
              ]}
              current={type}
              setCurrent={setType}
            />
          </div>
          <div className="p-4">
            <PopoverSelectorTitle title="Theme colors" />
            <ColorSelector color={color} setColor={onColorChange} variant='small' />
          </div>
          <div className="p-4">
            <PopoverSelectorTitle title="System colors" />
            <SystemColorSelector color={color} setColor={onColorChange} />
          </div>
          {
            !!color &&
            <div onClick={onColorUnset} className="mt-2 border-t border-gray-200 bg-gray-100 rounded-b-xl cursor-pointer">
              <div className="px-4 py-2 text-gray-700 text-sm text-center">
                <Icon icon='faUndo' className="mr-2" />
                Reset to default
              </div>
            </div>
          }
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default ColorDropdown;

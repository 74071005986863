import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { PageData } from '../api/api.types';

export interface Router {
  id: string;
  status: string;
  name: string;
  position: number;
  tab_visible: boolean;
  origin_page_id: string | null;
  builder_link: string;
  landing_link: string;
}

export interface NavRouter extends Router {
  isActive: boolean;
}

export interface PageNavigation {
  tab_nav_active: boolean;
  nav_router: NavRouter[];
  all_pages: Router[];
  current_page_id: string | null;
  nav_background_color: string | null;
  nav_display_logo: boolean;
  nav_text_color: string | null;
  nav_enabled: boolean;
  logo_url: string | null;
  seller_id: string | null;
  room_slug: string | null;
  template: boolean;
}

const initialState: PageNavigation = {
  tab_nav_active: false,
  nav_router: [],
  all_pages: [],
  nav_background_color: 'color-white',
  nav_display_logo: false,
  nav_text_color: 'color-black',
  nav_enabled: false,
  logo_url: null,
  seller_id: null,
  room_slug: null,
  current_page_id: null,
  template: false,
}


export const pageNavigationSlice = createSlice({
  name: 'page_navigation',
  initialState,
  reducers: {
    setPageNav: (state, action: PayloadAction<PageData>) => {
      const { room_pages, styles, room_slug, id, template, } = action.payload;
      const nav_router = room_pages.reduce((acc: NavRouter[], page) => {
        if (page.tab_visible) {
          acc.push({
            ...page,
            isActive: id === page.id,
          })
        }
        return acc
      }, [])

      return {
        tab_nav_active: !!nav_router.length || !!styles.seller.logo_url || template,
        nav_router,
        nav_background_color: styles.nav_background_color || state.nav_background_color,
        nav_display_logo: styles.nav_display_logo,
        nav_text_color: styles.nav_text_color || state.nav_text_color,
        nav_enabled: styles.nav_enabled,
        logo_url: styles?.seller?.logo_url,
        seller_id: styles?.seller?.id,
        all_pages: room_pages,
        current_page_id: id,
        room_slug,
        template,
      }
    },
    onPageNavBgColorChange: (state, action: PayloadAction<string>) => ({ ...state, nav_background_color: action.payload }),
    onPageNavTextColorChange: (state, action: PayloadAction<string>) => ({ ...state, nav_text_color: action.payload }),
    onPageNavLogoVisibleChange: (state, action: PayloadAction<boolean>) => ({ ...state, nav_display_logo: action.payload }),
    onPageNavVisibilityChange: (state, action: PayloadAction<boolean>) => ({ ...state, nav_enabled: action.payload }),
  }
})

export const { setPageNav, onPageNavBgColorChange, onPageNavTextColorChange, onPageNavLogoVisibleChange, onPageNavVisibilityChange } = pageNavigationSlice.actions

export default pageNavigationSlice.reducer

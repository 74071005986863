import React from 'react';
import { DropdownMenuProps } from '@radix-ui/react-dropdown-menu';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { collapseSelection, getParentNode, TElement, toggleBlock } from '@udecode/plate-common';
import { useEditorRef, useEditorSelector, focusEditor, ParagraphPlugin } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { toggleList, unwrapList, getListRoot } from '@udecode/plate-list';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';


import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
  useOpenState,
} from '../../../../shared/DropdownMenu/plate-ui-dropdown-menu';
import { ToolbarButton } from '../../../../shared/Toolbar/plate-ui-toolbar';
import Icon from '../../../../shared/Icon/Icon';

const items = [
  {
    value: ParagraphPlugin.key,
    label: 'Paragraph',
    description: 'Paragraph',
    icon: 'faParagraph'
  },
  {
    value: HEADING_KEYS.h1,
    label: 'Heading 1',
    description: 'Heading 1',
    icon: 'faHeading'
  },
  {
    value: HEADING_KEYS.h2,
    label: 'Heading 2',
    description: 'Heading 2',
    icon: 'faHeading'
  },
  {
    value: BulletedListPlugin.key,
    label: 'Bulleted list',
    description: 'Bulleted list',
    icon: 'faListUl'
  },
  {
    value: NumberedListPlugin.key,
    label: 'Numbered list',
    description: 'Numbered list',
    icon: 'faListOl'
  },
  {
    value: BlockquotePlugin.key,
    label: 'Quote',
    description: 'Quote',
    icon: 'faQuoteRight'
  },
];

const defaultItem = items.find((item) => item.value === ParagraphPlugin.key)!;

export function TurnIntoDropdownMenu(props: DropdownMenuProps) {

  const editor = useEditorRef();
  const openState = useOpenState();
  const selectedItem = useEditorSelector((editor) => {
    // Get parent node of selected text children
    const entry = getParentNode(editor, editor.selection as any)?.[0] as TElement
    if (entry) {
      return items.find((item) => {
        // Each item inside li element has a type of 'lic'
        if (entry && entry.type === 'lic') {
          // Find the parent node of the list item -> ol || ul
          const listRootElement = getListRoot(editor);
          if (listRootElement) {
            return listRootElement[0].type === item.value;
          }
        }
        if (entry) {
          return item.value === entry.type;
        }
        return item.value === ParagraphPlugin.key;
      }) || defaultItem
    } else {
      return defaultItem;
    }
  }, []);

  const { icon: SelectedItemIcon, label: selectedItemLabel } = selectedItem;

  return (
    <DropdownMenu modal={false} {...openState} {...props}>
      <DropdownMenuTrigger asChild>
        <ToolbarButton
          pressed={openState.open}
          tooltip="Turn into"
          isDropdown
        >
          <Icon icon={SelectedItemIcon} />
          <span className='px-1' >{selectedItemLabel}</span>
        </ToolbarButton>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="start" className="min-w-0 bg-white">
        <DropdownMenuLabel>Turn into</DropdownMenuLabel>

        <DropdownMenuRadioGroup
          className="flex flex-col gap-0.5"
          value={selectedItem.value}
          onValueChange={(type: string) => {
            if (type === BulletedListPlugin.key || type === NumberedListPlugin.key) {
              toggleList(editor, { type });
            } else {
              unwrapList(editor);
              toggleBlock(editor, { type });
            }
            collapseSelection(editor);
            focusEditor(editor);
          }}
        >
          {items.map(({ value: itemValue, label, icon }) => (
            <DropdownMenuRadioItem
              key={itemValue}
              value={itemValue}
              className="min-w-52"
            >
              <Icon icon={icon} className="mr-4 size-4" />
              {label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

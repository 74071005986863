import React from 'react';
import { cn, withRef } from '@udecode/cn';
import { ClassNames, TEditor, findNode } from '@udecode/plate-common';
import {
  DragItemNode,
  useDraggable,
  useDraggableState,
} from '@udecode/plate-dnd';
import { DropTargetMonitor } from 'react-dnd';
import { focusEditor, ParagraphPlugin, PlateElementProps, useEditorRef } from '@udecode/plate-common/react';
import { HEADING_KEYS } from '@udecode/plate-heading';
import { BlockquotePlugin } from '@udecode/plate-block-quote/react';
import { BulletedListPlugin, NumberedListPlugin } from '@udecode/plate-list/react';

import { Tooltip, TooltipContent, TooltipTrigger } from '../../shared/ToolTip/plate-ui-tooltip';
import Icon from '../../shared/Icon/Icon';
import { ColumnGroupPlugin } from '../../plate-config/Plugins/ColumnGroup/ColumnGroup.plugin';
import { PricingPlugin } from '../../plate-config/Plugins/Pricing/Pricing.plugin';
import { PlateSectionElement } from '../../plate-config/Plugins/Section/Section.plugin';
import { textColorFromColorClass } from '../../utils/color.util';
import { useAppSelector } from '../../store/hooks/redux-hooks';
import { ImagePlugin } from '../../plate-config/Plugins/Image/Image.plugin';
import { AiTextBlockPlugin } from '../../plate-config/Plugins/AiTextBlock/AiTextBlock.plugin';
import { AssessmentPlugin } from '../../plate-config/Plugins/Assessment/Assessment.plugin';
import { EmbedPlugin } from '../../plate-config/Plugins/Embed/Embed.plugin';
import { HorizontalRulePlugin } from '../../plate-config/Plugins/HrLine/HrLine.plugin';
import { VideoPlugin } from '../../plate-config/Plugins/Video/Video.plugin';

export interface DraggableProps
  extends PlateElementProps,
  ClassNames<{
    blockAndGutter: string;
    block: string;
    gutterLeft: string;
    blockToolbarWrapper: string;
    blockToolbar: string;
    blockWrapper: string;
    dragHandle: string;
    dragIcon: string;
    dropLine: string;
  }> {
  onDropHandler?: (
    editor: TEditor,
    props: {
      monitor: DropTargetMonitor<DragItemNode, unknown>;
      dragItem: DragItemNode;
      nodeRef: any;
      id: string;
    }
  ) => boolean;
}

export const Draggable = withRef<'div', DraggableProps>(
  ({ className, classNames = {}, onDropHandler, ...props }, ref) => {
    const { children, element } = props;
    const editor = useEditorRef();
    const colors = useAppSelector(state => state.page_colors.activePalette.colors)

    const state = useDraggableState({ element, onDropHandler });
    const { dropLine, isDragging, isHovered } = state;
    const {
      groupProps,
      gutterLeftProps,
      droplineProps,
      previewRef,
      handleRef,
    } = useDraggable(state);

    const isColumnGroupWideElement = element.type === ColumnGroupPlugin.key ? element.style === 'wide' : false;
    const isPricingTableWideElemnt = element.type === PricingPlugin.key ? element.style === 'wide' : false;
    const isImageElement = element.type === ImagePlugin.key;

    const node = findNode(editor, { at: [], match: { id: element.id } })!;
    const sectionParent = editor.children[node[1][0]] as PlateSectionElement;

    const activeSectionCardColor = sectionParent?.card_color
    const activeSectionBgColor = sectionParent?.tint_color
    const bgColour = activeSectionCardColor ? activeSectionCardColor : activeSectionBgColor
    const textColor = textColorFromColorClass(bgColour as string, colors)

    const isWideContent = sectionParent?.width === 'large';
    const isWideEl = isWideContent ? false : isColumnGroupWideElement || isPricingTableWideElemnt;
    const pricingTableMarker = sectionParent?.width === 'medium' ? 'left-[-145px]' : sectionParent?.width === 'small' ? "left-[-195px]" : "left-[-150px]"
    const columnGroupMarker = sectionParent?.width === 'medium' ? 'left-[-165px]' : sectionParent?.width === 'small' ? "left-[-215px]" : "left-[-175px]"
    const markerPositionForWideEl = isColumnGroupWideElement ? columnGroupMarker : pricingTableMarker

    const widthPercentage = parseFloat(element.width as string || '100%');
    const result = element.align === 'right' ? 100 - widthPercentage - 3 : (100 - widthPercentage) / (widthPercentage > 60 ? 2.5 : 2.2)

    const gutterClass = isWideEl ? markerPositionForWideEl : classNames.gutterLeft
    const adjustableGutterStyles = isImageElement && (element.align === 'center' || element.align === 'right') && !!element.width && !!element.image_url

    const selectableElements = [
      ParagraphPlugin.key, HEADING_KEYS.h1, HEADING_KEYS.h2, BlockquotePlugin.key, BulletedListPlugin.key,
      NumberedListPlugin.key, ImagePlugin.key, AiTextBlockPlugin.key, AssessmentPlugin.key, EmbedPlugin.key,
      HorizontalRulePlugin.key, PricingPlugin.key, VideoPlugin.key
    ] as string[]

    const onDragIconClick = () => {
      if (selectableElements.includes(element.type)) {
        focusEditor(editor)
        editor.select(node[1])
      }
      // If the element is a column group, select the first child, selecting the group itself will cause an error
      if (element.type === ColumnGroupPlugin.key) {
        focusEditor(editor)
        editor.select([...node[1], 0])
      }
    }

    return (
      <div
        ref={ref}
        className={cn(
          'relative',
          isDragging && 'opacity-50',
          'group',
          className
        )}
        {...groupProps}
      >
        <div
          className={`
          pointer-events-none absolute flex w-[16px] h-[24px]
          -translate-x-full cursor-text ${gutterClass}
           `}
          style={{ ...(adjustableGutterStyles ? { left: `${result <= 0 ? -1 : result}%` } : {}) }}
          {...gutterLeftProps}
        >
          <div className={cn(classNames.blockToolbarWrapper)}>
            <div
              className={cn(
                'pointer-events-auto mr-1 flex items-center',
                classNames.blockToolbar
              )}
            >
              <div ref={handleRef} className="size-4">
                {isHovered &&
                  <Tooltip>
                    <TooltipTrigger className='outline-none' onClick={onDragIconClick}>
                      <Icon icon="faGripVertical" className={`size-4 text-${textColor} rounded-sm p-[2px]`} />
                    </TooltipTrigger>
                    <TooltipContent className='bg-white'>Drag to move</TooltipContent>
                  </Tooltip>
                }
              </div>
            </div>
          </div>
        </div>

        <div className={classNames.blockWrapper} ref={previewRef}>
          {children}
          {!!dropLine && (
            <div
              className={cn(
                'absolute inset-x-0 border border-white',
                dropLine === 'top' && '-top-px',
                dropLine === 'bottom' && '-bottom-px',
              )}
              {...droplineProps}
            >
              <div
                className={cn(
                  'h-[4px] bg-blue-500 rounded-full',
                  classNames.dropLine
                )}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

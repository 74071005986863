import React, { useState, useCallback, useRef } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import ImagePicker from '../../../shared/ImagePicker/ImagePicker';
import { useAddUserImageMutation } from '../../../store/reducers/api/images/images';
import TooltipIcon from '../../../shared/ToolTip/TooltipIcon';
import { selectedBlockClasses } from '../../../utils/color.util';

interface DragItem {
  files: File[];
}

type Props = {
  isTemplate: boolean,
  setUploadedImageHandler: (imageId: string, imageUrl: string) => void,
  setImageFromPicker: (imageUrl: string, imageSource: string, imageId: string) => void,
  onImageUnset: () => void,
  onProgrammaticImageSet: (imageUrl: string, image_source: string | null) => void,
  imageSource: string,
  imageUrl: string,
  onImageDelete: () => void
  selected: boolean
}

const ImagePlaceholder = ({
  isTemplate, imageSource, imageUrl, setImageFromPicker, onImageUnset,
  onProgrammaticImageSet, setUploadedImageHandler, onImageDelete, selected
}: Props) => {

  const [showImagePicker, setShowImagePicker] = useState(false)
  const [uploadUserImage] = useAddUserImageMutation()
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileDrop = useCallback(async (_item: DragItem, monitor: DropTargetMonitor) => {
    if (monitor) {
      const dragItem = monitor.getItem() as DragItem;
      const imageFiles = dragItem.files.filter(file => file.type.startsWith('image/'));
      if (imageFiles.length === 0) {
        return;
      }
      const file = imageFiles[0] as File
      const formData = new FormData()
      formData.append("image[uploaded_image]", file)
      await uploadUserImage(formData).then((response) => {
        if ("data" in response) {
          setUploadedImageHandler(response.data.id, response.data.urls.regular)
        }
      })
    }
  }, []);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: handleFileDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  }), [handleFileDrop]);


  const handleUploadChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files;
    if (!files || !files[0].type.startsWith('image/')) return;
    if (e.target.files && e.target.files[0]) {
      const formData = new FormData()
      formData.append("image[uploaded_image]", e.target.files[0])
      await uploadUserImage(formData).then((response) => {
        if ("data" in response) {
          setUploadedImageHandler(response.data.id, response.data.urls.regular)
        }
      })
    }
  };

  const onUploadImageClick = () => inputRef && inputRef.current?.click();

  const onProfileImageUse = () => onProgrammaticImageSet('{{PROFILE_IMAGE}}', null);
  const onBrandImageUse = () => onProgrammaticImageSet('{{BUYER_LOGO}}', 'brand');

  return (
    <div
      ref={drop}
      className={` w-full rounded-xl text-gray-800 shadow-sm p-2
        ${selected ? selectedBlockClasses : ""}
        ${isOver ? 'border-blue-600 border-2 border-dashed bg-blue-100/80' : 'bg-gray-200/80 border-2 border-transparent'}`}
    >
      <input ref={inputRef} type="file" multiple={false} onChange={handleUploadChange} className="hidden" />
      <TooltipIcon
        tooltip="Delete"
        icon="faTrash"
        iconWrapperClassName="btn-small rounded-lg hover:bg-red-100 absolute right-1 top-1"
        side="top"
        container="image-element-tooltip-anchor"
        onClick={onImageDelete}
      />
      <div className='flex flex-col items-center'>
        <div className="text-lg pt-8 pb-4 text-center">Choose an Image</div>
        <div className="w-full flex flex-wrap justify-center items-center gap-6">
          <button
            className="btn btn-large btn-primary justify-center p-2 text-base whitespace-normal h-fit"
            onClick={onUploadImageClick}
          >
            Upload Image
          </button>
          <button
            className="btn btn-large btn-primary justify-center p-2 text-base whitespace-normal h-fit"
            onClick={setShowImagePicker.bind(setShowImagePicker, true)}
          >
            Browse Images
          </button>
        </div>
      </div>
      <div>
        {isTemplate && (
          <div className="flex flex-col items-center flex-wrap mb-8">
            <div className="text-lg text-center pt-12 pb-4">Programmatic Images</div>
            <div className="w-full flex flex-wrap justify-center items-center gap-6">
              <button
                className="btn btn-large btn-primary justify-center p-2 text-base whitespace-normal h-fit"
                onClick={onBrandImageUse}
              >
                Use Brand Logo
              </button>
              <button
                className="btn btn-large btn-primary justify-center p-2 text-base whitespace-normal h-fit"
                onClick={onProfileImageUse}
              >
                Use Profile Image
              </button>
            </div>
          </div>
        )}

      </div>
      <div className="text-sm py-6 text-center"> Or drag and drop your image file here </div>
      <ImagePicker
        backgroundImageSource={imageSource}
        currentImageUrl={imageUrl}
        modalOpen={showImagePicker}
        onImageChange={setImageFromPicker}
        onImageUnset={onImageUnset}
        onModalClose={setShowImagePicker.bind(setShowImagePicker, false)}
        brandImages={!isTemplate}
      />
    </div>
  )
}


export default ImagePlaceholder

import React, { memo, useEffect, useState } from 'react';
import { styled, keyframes } from '@stitches/react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';


type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  side?: TooltipPrimitive.TooltipContentProps['side'];
  container?: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

const Tooltip = memo(({
  children,
  content,
  side = 'top',
  container,
  open,
  onOpenChange,
}: Props) => {
  const scaleIn = keyframes({
    '0%': { opacity: 0, transform: 'scale(0)' },
    '100%': { opacity: 1, transform: 'scale(1)' },
  });
  const StyledContent = styled(TooltipPrimitive.Content, {
    transformOrigin: 'var(--radix-tooltip-content-transform-origin)',
    animation: `${scaleIn} 100ms ease-out`,
  });
  const TooltipArrow = styled(TooltipPrimitive.Arrow, {
    fill: '#e4e5e3',
  });

  const [containerElement, setContainerElement] = useState<HTMLElement | null | undefined>(document.body);

  useEffect(() => {
    if (container) {
      const element = document.getElementById(container);
      setContainerElement(element || document.body);
    }
  }, [container])

  const rootProps = !!onOpenChange ? { onOpenChange } : {}

  return (
    <TooltipPrimitive.Root
      open={open}
      {...rootProps}
    >
      <TooltipPrimitive.Trigger asChild contentEditable={false} suppressContentEditableWarning>
        {children}
      </TooltipPrimitive.Trigger>
      <TooltipPrimitive.Portal container={containerElement} >
        <StyledContent
          side={side}
          align="center"
          className="tooltip"
          contentEditable={false}
          suppressContentEditableWarning
        >
          {content}
          <TooltipArrow />
        </StyledContent>
      </TooltipPrimitive.Portal>
    </TooltipPrimitive.Root>
  )
})
export default Tooltip;
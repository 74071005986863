import React, { useState } from 'react';
import { useEditorRef, focusEditor } from '@udecode/plate-common/react';
import { select, setMarks, getMarks } from '@udecode/plate-common';

import Icon from '../../../../shared/Icon/Icon';
import ButtonSelector from '../../../../shared/ButtonSelector/ButtonSelector';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../shared/Popover/Popover';
import { ToolbarButton } from '../../../../shared/Toolbar/plate-ui-toolbar';
import { FONT_SIZING_OPTIONS } from '../../../Section/section.config';

const SizeDropdown = () => {
  const editor = useEditorRef()
  const marks = getMarks(editor)
  const [popoverOpen, setPopoverOpen] = useState(false)

  const onSizeChange = (font_size: string) => {
    setMarks(editor, { font_size });
    select(editor, editor.selection!);
    focusEditor(editor);
    setPopoverOpen(false)
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
      <PopoverTrigger asChild>
        <ToolbarButton tooltip={"Text size.."} pressed={popoverOpen} isDropdown={true}>
          <Icon icon='faUpRightAndDownLeftFromCenter' />
        </ToolbarButton>
      </PopoverTrigger>
      <PopoverContent align="center" sideOffset={5}  >
        <div className="mt-2">
          <ButtonSelector
            data={FONT_SIZING_OPTIONS}
            current={(marks?.font_size || 'medium') as string}
            setCurrent={onSizeChange}
            wrapperClassName="ring-4 ring-white"
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}

export default SizeDropdown;

import React from "react";
import { findNode, removeNodes } from "@udecode/plate-common";
import { isEditorReadOnly, PlateRenderElementProps } from "@udecode/plate-common/react";
import { useSelected } from "slate-react";

import { useAppSelector } from "../../store/hooks/redux-hooks";
import ImagePlaceholder from "./ImagePlaceholder/ImagePlaceholder";
import { PlateImageElement } from "../../plate-config/Plugins/Image/Image.plugin";
import ImagePopover from "./ImagePopover/ImagePopover";

const ImageVoidElement = ({
  attributes,
  children,
  element,
  editor
}: PlateRenderElementProps) => {
  const isTemplate = useAppSelector(state => state.page_addendums.template)
  const isReadOnly = isEditorReadOnly(editor)
  const selected = useSelected()

  const { id, image_source, image_url } = element as PlateImageElement
  // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
  // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
  const triggerToUpdate = useAppSelector(state => state.page_sections.sections)
  const nodePath = findNode(editor, { at: [], match: { id } })![1];
  const activeSection = editor.children[nodePath[0]];
  const sectionChildren = activeSection.children.filter(child => !!child.type)
  const isFirstChild = sectionChildren[0].id === id;
  const isLastChild = sectionChildren[sectionChildren.length - 1].id === id;

  const setUploadedImageHandler = (image_external_id: string, image_url: string) =>
    editor.setNodes(
      {
        image_external_id,
        image_source: "upload",
        image_url,
        internal_page_id: null,
        link_type: "external"
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const setImageFromPicker = (image_url: string, image_source: string, image_external_id: string) =>
    editor.setNodes(
      {
        image_external_id,
        image_source,
        image_url,
        internal_page_id: null,
        link_type: "external"
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onImageUnset = () =>
    editor.setNodes(
      {
        image_external_id: null,
        image_source: null,
        image_url: null,
        internal_page_id: null,
        link_type: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onProgrammaticImageSet = (image_url: string, image_source: string | null) =>
    editor.setNodes(
      {
        image_external_id: null,
        image_source,
        internal_page_id: null,
        image_url,
        link_type: 'external',
        shape: 'square',
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onAlignChange = (align: string) =>
    editor.setNodes(
      { align } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === id } }
    )

  const onShapeChange = (shape: string) =>
    editor.setNodes(
      { shape } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === id } }
    )

  const onImageSizeChange = (width: string) =>
    editor.setNodes(
      { width } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === id } }
    )

  const onUrlMenuChange = (url: string, link_type: string) =>
    editor.setNodes(
      {
        url,
        link_type,
        internal_page_id: null,
        internal_resource_id: null,
        internal_template_resource_slot_id: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => { return n.id === id } }
    )

  const onImageLinkResourceChange = (internal_resource_id: string) =>
    editor.setNodes(
      {
        url: null,
        link_type: 'resource',
        internal_resource_id,
        internal_template_resource_slot_id: null,
        internal_page_id: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onImageResourceSlotChange = (internal_template_resource_slot_id: string) =>
    editor.setNodes(
      {
        url: null,
        link_type: 'resource',
        internal_template_resource_slot_id,
        internal_page_id: null,
        internal_resource_id: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onLinkPageIdChange = (internal_page_id: string) =>
    editor.setNodes(
      {
        url: null,
        link_type: 'internal',
        internal_page_id,
        internal_resource_id: null,
        internal_template_resource_slot_id: null,
      } as Partial<Node>,
      { at: [], match: (n: any) => n.id === id }
    )

  const onImageDelete = () => removeNodes(editor, { at: [], match: (n: any) => n.id === id })

  if (isReadOnly) return image_url ?
    <div
      className={`
      page-block image-block
      ${isFirstChild ? 'first-section-child' : ""} 
      ${isLastChild ? 'last-section-child' : ""}
      `}
      id={element.id as string}
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-id={element.id}
      {...attributes}
    >
      <ImagePopover
        data-id={element.id}
        onImageUnset={onImageUnset}
        setImageFromPicker={setImageFromPicker}
        element={element as PlateImageElement}
        onAlignChange={onAlignChange}
        onShapeChange={onShapeChange}
        onUrlMenuChange={onUrlMenuChange}
        onImageSizeChange={onImageSizeChange}
        isReadOnly={isReadOnly}
        onImageLinkResourceChange={onImageLinkResourceChange}
        onImageResourceSlotChange={onImageResourceSlotChange}
        onLinkPageIdChange={onLinkPageIdChange}
        isTemplate={isTemplate}
        selected={false}
      />
      {children}
    </div>
    : null

  return (
    <div
      className={`
      page-block image-block
      ${isFirstChild ? 'first-section-child' : ""} 
      ${isLastChild ? 'last-section-child' : ""}
      `}
      id={element.id as string}
      contentEditable={false}
      suppressContentEditableWarning={true}
      data-plate-selectable
      data-id={element.id}
      {...attributes}
    >
      {children}
      {image_url
        ?
        <ImagePopover
          onImageUnset={onImageUnset}
          setImageFromPicker={setImageFromPicker}
          element={element as PlateImageElement}
          onAlignChange={onAlignChange}
          onShapeChange={onShapeChange}
          onUrlMenuChange={onUrlMenuChange}
          onImageSizeChange={onImageSizeChange}
          onImageLinkResourceChange={onImageLinkResourceChange}
          onImageResourceSlotChange={onImageResourceSlotChange}
          onLinkPageIdChange={onLinkPageIdChange}
          isTemplate={isTemplate}
          selected={selected}
        />
        :
        <ImagePlaceholder
          isTemplate={isTemplate}
          setUploadedImageHandler={setUploadedImageHandler}
          setImageFromPicker={setImageFromPicker}
          onImageUnset={onImageUnset}
          onProgrammaticImageSet={onProgrammaticImageSet}
          imageSource={image_source as string}
          imageUrl={image_url as string}
          onImageDelete={onImageDelete}
          selected={selected}
        />
      }
    </div>
  )
}

export default ImageVoidElement

import React, { memo } from 'react'
import ReactPlayer from 'react-player/lazy'

type Props = {
  link: string
}

const VideoSourceSelector: React.FC<Props> = memo(({ link }) => {
  const isLoomVideo = /loom\.com/i.test(link)
  const isVidyardVideo = /vidyard\.com/i.test(link)

  return (
    <div className="video-player-wrapper aspect-video">
      {isLoomVideo || isVidyardVideo ? (
        <iframe
          src={isLoomVideo ? link.replace(/share/i, "embed") : link}
          allowFullScreen
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        />
      )
        : (
          <ReactPlayer
            url={link}
            className="react-player"
            width="100%"
            height="100%"
          />
        )
      }
    </div>
  )
})

export default VideoSourceSelector
import React from "react";

import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables";
import TooltipButton from "../../../../../shared/ToolTip/TooltipButton";
import VariableForm from "../VariableForm/VariableForm";
import { PageVariable } from "../../../../../store/reducers/api/api.types";

type Props = {
  variable: StoreCalcVariable;
  handleVariableUpdate: (variable: PageVariable) => void;
  handleVariableDelete: (id: string) => void;
  setFormId: (id: string | null) => void;
  formId: string | null;
}

const VariableCard: React.FC<Props> = ({ variable, handleVariableUpdate, handleVariableDelete, setFormId, formId }) => {
  const { handle, label, minimum, maximum, default: value, hint, slider_default, line_item_default, id } = variable;

  return (
    <div id="variable-card-tooltip-anchor"
      className={`border-solid border rounded-xl shadow-sm ${id === formId ? "border-blue-300 ring ring-blue-200 ring-opacity-50 shadow-sm" : "border-gray-200"}`}
    >
      <div className="bg-gray-50 rounded-t-xl rounded-b-xl">
        <div className="px-4 py-2">
          <div className="flex justify-between items-center font-semibold mb-1">
            <div className="text-gray-600 font-mono text-xs">
              <span>{"{{"}</span>{handle}<span className="mr-1">{"}}"}</span>
            </div>
            <div> {label} </div>
            <TooltipButton
              btnClassName="btn btn-clear btn-icon rounded-full text-base text-gray-600"
              icon='faPen'
              onClick={setFormId.bind(setFormId, id === formId ? null : id)}
              tooltip="Edit Variable"
              side="top"
              container="variable-card-tooltip-anchor"
            />
          </div>
          {id !== formId &&
            <>
              {hint && <div className="flex justify-between pt-1 text-gray-600 text-xs">Hint: {hint}</div>}
              <div className="flex justify-between py-2" >
                {!!minimum && <div className="flex justify-between pt-1 text-gray-600 text-xs">Min: {minimum}</div>}
                <div className="flex justify-between pt-1 text-gray-600 text-xs">Value: {value}</div>
                {!!maximum && <div className="flex justify-between pt-1 text-gray-600 text-xs">Max: {maximum}</div>}
              </div>
              {(slider_default || line_item_default) &&
                <div className="flex justify-center pt-1 text-gray-600 text-xs">
                  {slider_default && "Slider Default"} {line_item_default && "Pricing Line Item Default"}
                </div>
              }
            </>
          }
        </div>
      </div>
      {id === formId &&
        <VariableForm
          variable={variable}
          onFormClose={setFormId.bind(setFormId, null)}
          onVariableUpdate={handleVariableUpdate}
          onVariableDelete={handleVariableDelete}
        />
      }
    </div>
  );
}

export default VariableCard;
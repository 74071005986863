import React, { useState } from 'react';
import validator from 'validator';

import Icon from '../../../../shared/Icon/Icon';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../shared/Popover/Popover';
import ButtonSelector from '../../../../shared/ButtonSelector/ButtonSelector';
import { RoomPages } from '../../../../store/reducers/api/api.types';
import TooltipDropdown from '../../../../shared/ToolTip/TooltipDropdown';
import { useAppSelector } from '../../../../store/hooks/redux-hooks';
import { PlateButtonElement } from '../../../../plate-config/Plugins/Button/Button.plugin';
import IconButton from '../../../../shared/IconButton/IconButton';

type Props = {
  element: PlateButtonElement
  onUrlChange: (value: string, type: string) => void,
  onPageIdChange: (pageId: string) => void,
  template: boolean,
  pages: RoomPages[],
  setLinkResource: (resourceId: string) => void,
  setLinkResourceSlot: (resourceSlotId: string) => void,
}

const LinkToolbarSelector = ({
  onUrlChange, template, pages, element,
  onPageIdChange, setLinkResource, setLinkResourceSlot
}: Props) => {

  const { resource_slots, resources } = useAppSelector(state => state.page_resources)
  const currentPageId = useAppSelector(state => state.page_addendums.pageId)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [linkVariant, setLinkVariant] = useState(element.link_type || "external")
  const [url, setUrl] = useState(element.url || "")

  const [error, setError] = useState<string | null>(null)

  const LINK_OPTIONS = [
    { name: "external", label: "External" },
    ...(template ? [{ name: "dynamic", label: "Dynamic" }] : [{ name: "internal", label: "Page in Room" }]),
    ...(resources.length > 0 ? [{ name: "resource", label: "Resource" }] : []),
    ...(template && resource_slots.length > 0 ? [{ name: "resource", label: "Resource" }] : [])
  ]
  const DYNAMIC_LINKS = [
    { key: "transcript", value: "{{LINKS.TRANSCRIPT}}", button: "Transcript Page" },
    { key: "book-meetings", value: "{{LINKS.BOOK_MEETINGS}}", button: "Book Meetings URL" },
  ]

  const onUrlChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(null)
    setUrl(event.target.value)
  }

  const onDynamicLinkChangeHandler = (value: string) => {
    setUrl(value)
    onUrlChange(value, linkVariant)
  }

  const onUrlAcceptHandler = () => {
    if (validator.isURL(url, { require_protocol: true })) {
      setError(null)
      onUrlChange(url as string, linkVariant)
      setPopoverOpen(false)
    } else {
      setError("Invalid URL")
    }
  }

  const setLinkPage = (pageId: string) => {
    setUrl('')
    setLinkVariant('internal')
    onPageIdChange(pageId)
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger >
        <TooltipDropdown
          tooltip='Edit Link'
          side='top'
          container='button-element-popover-anchor'
          icon='faLink'
        />
      </PopoverTrigger>
      <PopoverContent
        align="center"
        sideOffset={5}
        className="border-solid border p-2 flex flex-col border-gray-200 rounded-xl bg-white drop-shadow z-30"
      >
        <ButtonSelector data={LINK_OPTIONS} current={linkVariant} setCurrent={setLinkVariant} />
        {linkVariant === "external" && (
          <>
            <div className="flex flex-row items-center gap-4 mt-4">
              <input
                type='text'
                className='form-control'
                placeholder="https://example.com"
                onChange={onUrlChangeHandler}
                value={url as string}
              />
              <IconButton
                btnClassName='btn btn-primary only-child'
                icon='faCheck'
                onClick={onUrlAcceptHandler}
              />
            </div>
            {error && (
              <div className="flex items-center text-sm text-red-500 mt-2 animate-fadeIn bg-red-50 border-l-4 border-red-500 p-2">
                <Icon icon='faExclamationTriangle' className='w-4 h-4 mr-2' />
                {error}
              </div>
            )}
          </>
        )}
        {linkVariant === "internal" && (
          <div className="flex flex-col gap-4 mt-4">
            {pages.map((page, i) =>
              <button
                key={i}
                className={`btn ${page.id === element.internal_page_id ? "btn-primary" : "btn-white"} only-child`}
                onClick={setLinkPage.bind(setLinkPage, page.id)}
                disabled={page.id === currentPageId}
              >
                <span className={`${page.id === element.internal_page_id ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                  {page.id === element.internal_page_id && <Icon icon='faCheck' />}
                  {page.name}
                </span>
              </button>
            )}
          </div>
        )}
        {linkVariant === "dynamic" &&
          <div className="flex flex-col gap-4 mt-4">
            {DYNAMIC_LINKS.map((dynamicLink, i) =>
              <button
                key={i}
                className={`btn ${url == dynamicLink.value ? "btn-primary" : "btn-white"} only-child`}
                onClick={onDynamicLinkChangeHandler.bind(onDynamicLinkChangeHandler, dynamicLink.value)}
              >
                <span className={`${url == dynamicLink.value ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                  {url == dynamicLink.value && <Icon icon='faCheck' />}
                  {dynamicLink.button}
                </span>
              </button>
            )}
          </div>
        }
        {
          linkVariant === "resource" &&
          <div className="flex flex-col gap-4 mt-4">
            <>
              {template && resource_slots.length > 0 &&
                <>
                  {resource_slots.map((resourceSlot) => (
                    <button
                      key={`resource-slot-link-${resourceSlot.id}`}
                      className={`btn ${element.internal_template_resource_slot_id == resourceSlot.id ? "btn-primary" : "btn-white"} only-child`}
                      onClick={setLinkResourceSlot.bind(setLinkResourceSlot, resourceSlot.id)}
                    >
                      <span className={`${element.internal_template_resource_slot_id == resourceSlot.id ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                        {element.internal_template_resource_slot_id == resourceSlot.id && <Icon icon='faCheck' />}
                        {resourceSlot.about}
                      </span>
                    </button>
                  ))}
                </>
              }
              {
                !template && resources.length > 0 &&
                <>
                  {resources.map((resource) => (
                    <button
                      key={`resource-link-${resource.id}`}
                      className={`btn ${element.internal_resource_id == resource.id ? "btn-primary" : "btn-white"} only-child`}
                      onClick={setLinkResource.bind(setLinkResource, resource.id)}
                    >
                      <span className={`${element.internal_resource_id == resource.id ? "font-bold" : ""} flex flex-row items-center gap-4`}>
                        {element.internal_resource_id == resource.id && <Icon icon='faCheck' />}
                        {resource.name}
                      </span>
                    </button>
                  ))}
                </>
              }
            </>
          </div>
        }
      </PopoverContent>
    </Popover>
  );
}

export default LinkToolbarSelector;
import React from 'react';
import { withRef } from '@udecode/cn';
import { useLinkToolbarButton, useLinkToolbarButtonState } from '@udecode/plate-link/react';

import { ToolbarButton } from '../../../../shared/Toolbar/plate-ui-toolbar';
import Icon from '../../../../shared/Icon/Icon';


export const LinkToolbarButton = withRef<typeof ToolbarButton>((rest, ref) => {
  const state = useLinkToolbarButtonState();
  const { props } = useLinkToolbarButton(state);

  return (
    <ToolbarButton ref={ref} tooltip="Link" {...props} {...rest}>
      <Icon icon='faLink' />
    </ToolbarButton>
  );
});
import { TElement, TNodeEntry } from '@udecode/plate-common';
import { createPlatePlugin, PlateEditor, ParagraphPlugin } from '@udecode/plate-common/react';
import { v4 as uuidv4 } from 'uuid';

import { createDefaultParagraphElement } from "../DefaultMockups/DefaultMockups";
import { ColumnGroupPlugin } from './ColumnGroup.plugin';
import { SectionPlugin } from '../Section/Section.plugin';
import { ButtonPlugin } from '../Button/Button.plugin';


const extendEditor = ({ editor }) => {
  const { normalizeNode, apply } = editor as PlateEditor;
  editor.normalizeNode = ([node, path]: TNodeEntry) => {
    // Add empty paragraph if section has no children (I don't use insertNode because it will 
    // trigger normalizeNode again and editor will add empty block)
    if (node.type === ColumnPlugin.key) {
      const column = node as PlateColumnElement;
      if (column.children.length === 0) {
        node.children = [{ type: ParagraphPlugin.key, children: [{ text: '' }], id: uuidv4() }]
      }
    }
    // Column should not have nested columns, and elements that should not exist without parent
    if (node.type === ColumnPlugin.key) {
      const column = node as PlateColumnElement;
      column.children.map((child) => {
        if (
          child.type === ColumnPlugin.key ||
          child.type === ColumnGroupPlugin.key ||
          child.type === ButtonPlugin.key ||
          child.type === SectionPlugin.key
        ) {
          editor.removeNodes({ at: [], match: (n: any) => n.id === child.id });
        }
      });
    }
    normalizeNode([node, path]);
  };
  editor.apply = (unit: any) => {
    // Restrict merging columns
    if (unit.type === "merge_node" && unit.properties.type === ColumnPlugin.key) {
      return
    }
    return apply(unit);
  };

  return editor;
};

export const ColumnPlugin = createPlatePlugin({
  key: 'column',
  node: {
    type: 'column',
    isElement: true,
  },
  extendEditor: extendEditor,
  priority: 800
});

export interface PlateColumnElement extends TElement {
  id: string
  type: typeof ColumnPlugin.key,
  width: number,
}

export const createDefaultColumnElement = (): TElement => ({
  type: ColumnPlugin.key,
  children: [createDefaultParagraphElement()],
  width: 50,
})

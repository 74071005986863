import React from 'react';
import { cn, withRef } from '@udecode/cn';
import { findNode } from '@udecode/plate-common';
import { PlateElement } from '@udecode/plate-common/react';

export const BlockquoteElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    const nodePath = findNode(editor, { at: [], match: { id: element.id } })![1];
    const activeSection = editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === element.id;

    return (
      <PlateElement
        ref={ref}
        id={element.id as string}
        editor={editor}
        element={element}
        data-id={element.id}
        asChild
        className={cn(`
        border-l-2 pl-6 italic text-base page-block quote-block
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        `,
          className)}
        {...props}
      >
        <blockquote>{children}</blockquote>
      </PlateElement>
    );
  }
);

import React from "react"

import Icon from "../Icon/Icon"


type Props = {
  color: string
  setColor: (color: string) => void
}

const SystemColorSelector = ({ color, setColor }: Props) => {
  const onColorChangeHandle = (name: string) => setColor(name)
  return (
    <div className="w-full">
      <div className="flex flex-wrap text-center gap-2 mt-1">
        {systemColorsArray.map(backgroundColor =>
          <React.Fragment key={`${backgroundColor.name}-button-color`} >
            <button
              onClick={onColorChangeHandle.bind(onColorChangeHandle, backgroundColor.name)}
              className='h-8 w-8 rounded-full border border-gray-300 hover:ring-gray-200 hover:ring'
              style={{ backgroundColor: backgroundColor.color, color: 'white' }}
            >
              {backgroundColor.name === color && <Icon icon="faCheck" />}
            </button>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default SystemColorSelector


export const systemColorsArray = [
  { name: 'landing-red-300', color: '#fca5a5' },
  { name: 'landing-red-500', color: '#ef4444' },
  { name: 'landing-red-700', color: '#991b1b' },
  { name: 'landing-orange-300', color: '#fbd38d' },
  { name: 'landing-orange-500', color: '#ed8936' },
  { name: 'landing-orange-700', color: '#7f3c1e' },
  { name: 'landing-yellow-300', color: '#faf089' },
  { name: 'landing-yellow-500', color: '#f59e0b' },
  { name: 'landing-yellow-700', color: '#78350f' },
  { name: 'landing-green-300', color: '#9ae6b4' },
  { name: 'landing-green-500', color: '#22c55e' },
  { name: 'landing-green-700', color: '#22543d' },
  { name: 'landing-blue-300', color: '#93c5fd' },
  { name: 'landing-blue-500', color: '#2563eb' },
  { name: 'landing-blue-700', color: '#1d4ed8' },
  { name: 'landing-purple-300', color: '#d6bcfa' },
  { name: 'landing-purple-500', color: '#9333ea' },
  { name: 'landing-purple-700', color: '#6d28d9' },
]

import React from "react";
import { useForm, SubmitHandler } from "react-hook-form"

import { StoreCalcVariable } from "../../../../../store/reducers/page_calc_variables/page_calc_variables";
import { PageVariable } from "../../../../../store/reducers/api/api.types";
import { CreateCalcVariableInput } from "../../../../../store/reducers/api/calc_variables/calc_variables.api";

type Props = {
  variable?: StoreCalcVariable;
  onFormClose?: () => void;
  onVariableUpdate?: (variable: PageVariable) => void;
  onVariableCreate?: (variable: CreateCalcVariableInput) => void;
  onVariableDelete?: (id: string) => void;
}

interface IFormInput {
  default: number
  label?: string
  hint?: string
  prefix?: string
  suffix?: string
  maximum?: number
  minimum?: number
}

const VariableForm: React.FC<Props> = ({ variable, onFormClose, onVariableCreate, onVariableDelete, onVariableUpdate }) => {
  const isEdit = !!variable;

  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>({
    defaultValues: {
      label: variable?.label || "",
      hint: variable?.hint || "",
      default: variable?.default || 0,
      maximum: variable?.maximum || 0,
      minimum: variable?.minimum || 0,
      prefix: variable?.prefix || "",
      suffix: variable?.suffix || ""
    }
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (isEdit) {
      onVariableUpdate && onVariableUpdate({
        ...variable,
        label: data.label ? data.label.trim().length > 0 ? data.label : null : null,
        hint: data.hint ? data.hint.trim().length > 0 ? data.hint : null : null,
        default: data.default,
        maximum: data.maximum ? data.maximum : null,
        minimum: data.minimum ? data.minimum : null,
        prefix: data.prefix ? data.prefix.trim().length > 0 ? data.prefix : null : null,
        suffix: data.suffix ? data.suffix.trim().length > 0 ? data.suffix : null : null
      } as any);
    } else {
      onVariableCreate && onVariableCreate({
        label: data.label ? data.label.trim().length > 0 ? data.label : null : null,
        hint: data.hint ? data.hint.trim().length > 0 ? data.hint : null : null,
        default: data.default,
        maximum: data.maximum ? data.maximum : null,
        minimum: data.minimum ? data.minimum : null,
        prefix: data.prefix ? data.prefix.trim().length > 0 ? data.prefix : null : null,
        suffix: data.suffix ? data.suffix.trim().length > 0 ? data.suffix : null : null
      });
    }
  }

  const valueValidation = (value: number, formValues: IFormInput) => {
    if (!value || value === 0 || value < 0) return "Value should be greater than 0";
    if (formValues.minimum && value < formValues.minimum) return "Value should be greater than minimum";
    if (formValues.maximum && value > formValues.maximum) return "Value should be less than maximum";
    return true
  }

  const minValidation = (value: number | undefined, formValues: IFormInput) => {
    if (formValues.maximum && !value) {
      return "Min should be less than maximum"
    }
    if (value === 0) {
      return "Min should be greater than 0";
    }
    if (value && value > 0 && formValues.maximum && value > formValues.maximum) {
      return "Min should be less than maximum";
    }
    return true
  }

  const maxValidation = (value: number | undefined, formValues: IFormInput) => {
    if (formValues.minimum && !value) {
      return "Max should be greater than minimum"
    }
    if (value === 0) {
      return "Max should be greater than 0";
    }
    if (value && value > 0 && formValues.minimum && value < formValues.minimum) {
      return "Max should be greater than minimum";
    }
    return true
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="font-base flex flex-col gap-3 px-4 pb-4 pt-2">
      <div>
        <label className="block text-sm font-medium text-gray-700">Value:</label>
        <input {...register("default", { required: "Value is required", valueAsNumber: true, validate: valueValidation })}
          type="number"
          className="mt-1 p-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
        {errors.default && <p className="mt-2 text-sm text-red-600">{errors.default.message}</p>}
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Name:</label>
        <input
          {...register("label")} type="text"
          className="mt-1 p-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md"
        />
        {errors.label && <p className="mt-2 text-sm text-red-600">{errors.label.message}</p>}
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Hint:</label>
        <input
          {...register("hint")} type="text"
          className="mt-1 p-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md"
        />
        {errors.hint && <p className="mt-2 text-sm text-red-600">{errors.hint.message}</p>}
      </div>
      <div className="flex gap-1">
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700">Prefix:</label>
          <input {...register("prefix")} type="text" className="mt-1 p-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
          {errors.prefix && <p className="mt-2 text-sm text-red-600">{errors.prefix.message}</p>}
        </div>
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700">Suffix:</label>
          <input {...register("suffix")} type="text" className="mt-1 p-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
          {errors.suffix && <p className="mt-2 text-sm text-red-600">{errors.suffix.message}</p>}
        </div>
      </div>
      <div className="flex gap-1">
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700">Minimum</label>
          <input
            {...register("minimum", { valueAsNumber: true, validate: minValidation })}
            type="number"
            className="mt-1 p-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
          {errors.minimum && <p className="mt-2 text-sm text-red-600">{errors.minimum.message}</p>}
        </div>
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700">Maximum</label>
          <input
            {...register("maximum", { valueAsNumber: true, validate: maxValidation })}
            type="number"
            className="mt-1 p-2 border border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm rounded-md" />
          {errors.maximum && <p className="mt-2 text-sm text-red-600">{errors.maximum.message}</p>}
        </div>
      </div>
      <div className="flex gap-2">
        <button type="submit" className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {isEdit ? "Update" : "Create"}
        </button>
        <button
          onClick={isEdit ? onVariableDelete?.bind(onVariableDelete, variable.id) : onFormClose}
          className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
          {isEdit ? "Delete" : "Cancel"}
        </button>
      </div>
    </form>
  );
}

export default VariableForm;
import React from 'react';
import { withRef, withVariants } from '@udecode/cn';
import { findNode } from '@udecode/plate-common';
import { PlateElement } from '@udecode/plate-common/react';
import { cva } from 'class-variance-authority';


const headingVariants = cva('', {
  variants: {
    variant: {
      h1: 'font-heading text-4xl font-bold page-block header-block',
      h2: 'font-heading text-xl font-semibold tracking-tight page-block header-block',
    },
  },
});

const HeadingElementVariants = withVariants(PlateElement, headingVariants);

export const HeadingElement = withRef<typeof HeadingElementVariants>(
  ({ variant = 'h1', children, className, ...props }, ref) => {
    const Element = variant!;

    const nodePath = findNode(props.editor, { at: [], match: { id: props.element.id } })![1];
    const activeSection = props.editor.children[nodePath[0]];
    const sectionChildren = activeSection.children.filter(child => !!child.type)
    const isFirstChild = sectionChildren[0].id === props.element.id;
    const isLastChild = sectionChildren[sectionChildren.length - 1].id === props.element.id;

    return (
      <HeadingElementVariants
        ref={ref}
        asChild
        variant={variant}
        id={props.element.id as string}
        data-id={props.element.id}
        className={`
        ${className}
        ${isFirstChild ? 'first-section-child' : ""} 
        ${isLastChild ? 'last-section-child' : ""}
        `}
        {...props}
      >
        <Element>{children}</Element>
      </HeadingElementVariants>
    );
  }
);

import React, { memo } from "react"

import Icon from "../Icon/Icon"
import Tooltip from "./Tooltip"
import { twMerge } from "tailwind-merge"

type Props = {
  tooltip: string
  side: "top" | "right" | "bottom" | "left" | undefined
  container: string
  icon: string
  iconClassName?: string
  iconsWrapperClassName?: string
}

const TooltipDropdown: React.FC<Props> = memo(({ tooltip, side, container, icon, iconsWrapperClassName, iconClassName }) => {
  return (
    <Tooltip content={tooltip} side={side} container={container} >
      <div className={twMerge('btn-small flex items-center hover:bg-white rounded-lg', iconsWrapperClassName)} >
        <Icon className={twMerge('pr-1', iconClassName)} icon={icon} />
        <Icon icon='faChevronDown' className='button-dropdown-toggle' />
      </div>
    </Tooltip>
  )
})

export default TooltipDropdown

import React from 'react';
import { FacebookEmbed, InstagramEmbed, XEmbed, LinkedInEmbed, TikTokEmbed } from 'react-social-media-embed';
import { InlineWidget } from "react-calendly";

import { EmbedConfig, EmbedPlatform } from './config/config';

type Props = {
    platform: EmbedConfig
}

const EmbedViewController = ({ platform }: Props) => {
    let view: null | React.ReactNode = null

    switch (platform.name) {
        case EmbedPlatform.Facebook:
            view = <FacebookEmbed url={platform.link as string} width={"100%"} />
            break;
        case EmbedPlatform.Instagram:
            view = <InstagramEmbed url={platform.link as string} width={"100%"} />
            break;
        case EmbedPlatform.Twitter:
            view = <XEmbed url={platform.link as string} width={"100%"} />
            break;
        case EmbedPlatform.LinkedIn:
            view = <LinkedInEmbed url={platform.link as string} width={"100%"} />
            break;
        case EmbedPlatform.TikTok:
            view = <TikTokEmbed url={platform.link as string} style={{ minWidth: '325px' }} />
            break;
        case EmbedPlatform.Pitch:
            view = <iframe className="w-full pitch-embed-iframe" src={platform.link} title={platform.name} />
            break;
        case EmbedPlatform.Miro:
            view = <iframe className="w-full miro-embed-iframe" src={platform.link} title={platform.name} />
            break;
        case EmbedPlatform.GoogleDocs:
            const docsUrl = platform.link?.endsWith('?embedded=true') ? platform.link : platform.link + '?embedded=true'
            view = <iframe className="w-full google-docs-embed-iframe" src={docsUrl} title={platform.name} />
            break;
        case EmbedPlatform.GoogleSheets:
            const sheetsUrl = platform.link?.endsWith("pubhtml") ? platform.link + '?widget=true&amp;headers=false' : platform.link
            view = <iframe className="w-full google-sheets-embed-iframe" src={sheetsUrl} title={platform.name} />
            break;
        case EmbedPlatform.GoogleSlides:
            const slidesUrl = platform.link?.replace("pub?", "embed?")
            view = <iframe
                className="w-full google-slides-embed-iframe"
                src={slidesUrl}
                title={platform.name}
                allowFullScreen
            />
            break;
        case EmbedPlatform.GoogleForms:
            view = <iframe className="w-full google-forms-embed-iframe" src={platform.link} title={platform.name} />
            break;
        case EmbedPlatform.Calendly:
            // NOTE: Don't set width: '100%' it will add ugly margins on top and on bottom 
            // they are not removable, because we can't interact with styles inside of iframe document
            view = <InlineWidget url={platform.link as string} styles={{ width: '99%', height: '500px' }} />
            break;
        case EmbedPlatform.HubSpotForms:
            view = <iframe className="w-full hubspot-forms-embed-iframe" src={platform.link} title={platform.name} />
            break;
        default:
            view = null;
    }
    return (
        <> {view ? <div className="flex justify-center">{view}</div> : null} </>
    )
}

export default EmbedViewController;



import React, { useCallback, useState } from "react";
import { withRef } from '@udecode/cn';
import { findNode, getNodeParent } from "@udecode/plate-common";
import { isEditorReadOnly, PlateElement } from "@udecode/plate-common/react";
import ahoy from "ahoy.js"

import { useAppSelector } from "../../store/hooks/redux-hooks";
import { colorClassToRGB } from "../../utils/color.util";
import Icon from "../../../components/shared/Icon/Icon";
import { PlateButtonElement } from "../../plate-config/Plugins/Button/Button.plugin";
import { Popover, PopoverContent, PopoverTrigger } from "../../shared/Popover/Popover";
import TooltipIcon from '../../shared/ToolTip/TooltipIcon';
import ColorToolbarSelector from "./Toolbar/ColorToolbarSelector/ColorToolbarSelector";
import LinkToolbarSelector from "./Toolbar/LinkToolbarSelector/LinkToolbarSelector";
import TooltipLink from "../../shared/ToolTip/TooltipLink";
import ToolbarDropdownSelector from "../../shared/Toolbar/ToolbarDropdownSelector";



export const ButtonVoidElement = withRef<typeof PlateElement>(
  ({ className, children, editor, element, ...props }, ref) => {
    // const selected = useSelected();
    const block = element as PlateButtonElement
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    // Has to be a local state to avoid case with cursor jumping to the end of the input
    const [content, setContent] = useState(block.content || '')

    const analyticsEnabled = useAppSelector(state => state.page_side_effects.analyticsEnabled)
    const { pageId, account_id, pages, template, isLandingPage } = useAppSelector(state => state.page_addendums)
    const node = findNode(editor, { at: [], match: { id: block.id } })!
    const section = editor.children[node[1][0]]

    const trackBtnClick = useCallback((href: string) => {
      if (!analyticsEnabled) return
      console.log("Tracking button click", {
        page_id: pageId,
        section_id: section.id,
        block_id: block.id,
        type: "button_click",
        button_text: block.content,
        account_id,
        href,
      });
      return ahoy.track("page action", {
        page_id: pageId,
        section_id: section.id,
        block_id: block.id,
        type: "button_click",
        button_text: block.content,
        account_id,
        href,
      });
    }, [pageId, section, block]);


    // CRITICAL NOTE:  Element can depend on general state structure, for example on section font_color, font_size, etc.
    // Due to restrictions of using editorState function, we use redux store to trigger re-render of element (triggerToUpdate).
    const triggerToUpdate = useAppSelector(state => state.page_sections.sections)

    const isReadOnly = isEditorReadOnly(editor);
    const { resources } = useAppSelector(state => state.page_resources)
    const { activePalette } = useAppSelector(state => state.page_colors)

    const elementNode = findNode(editor, { at: [], match: (n: any) => n.id === block.id });
    const parentNode = elementNode && getNodeParent(editor, elementNode[1]);

    const matchingPage = pages?.find(page => page.id === block.internal_page_id)
    const matchingResource = resources?.find(resource => resource.id === block.internal_resource_id)

    const externalLinkPath = block.url && block.url.length > 0 ? block.url : null
    const internalLinkPath = matchingPage ? isLandingPage ? matchingPage.landing_link : matchingPage.builder_link : null
    const resourceLinkPath = matchingResource ? matchingResource.goto_url : null

    const goToLink = block.link_type === "external" ? externalLinkPath :
      block.link_type === "internal" ? internalLinkPath :
        block.link_type === "resource" ? resourceLinkPath : null

    const missingUrl =
      (block.link_type === "external" && !block.url) ||
      (block.link_type === "internal" && !block.internal_page_id) ||
      (block.link_type === "resource" && !block.internal_resource_id && !block.internal_template_resource_slot_id)

    const onButtonDelete = () =>
      editor.removeNodes({ at: [], match: (n: any) => n.id === block.id })

    const onContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setContent(event.target.value)
      editor.setNodes({ content: event.target.value } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })
    }

    const onAlignmentChange = (align: string) =>
      editor.setNodes({ align } as Partial<Node>, { at: [], match: (n: any) => n.id === parentNode?.id })

    const onSizeChange = (button_size: string) =>
      editor.setNodes({ button_size } as Partial<Node>, { at: [], match: (n: any) => n.id === parentNode?.id })

    const onButtonColorChange = (primary_color: string) =>
      editor.setNodes({ primary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const onTextColorChange = (secondary_color: string) =>
      editor.setNodes({ secondary_color } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const onButtonUrlChange = (url: string, link_type: string) =>
      editor.setNodes(
        {
          url,
          link_type,
          internal_page_id: null,
          internal_resource_id: null,
          internal_template_resource_slot_id: null
        } as Partial<Node>, { at: [], match: (n: any) => n.id === block.id })

    const onButtonLinkPageIdChange = (internal_page_id: string) =>
      editor.setNodes(
        {
          url: null,
          link_type: 'internal',
          internal_page_id,
          internal_resource_id: null,
          internal_template_resource_slot_id: null
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )

    const onButtonLinkResourceChange = (internal_resource_id: string) =>
      editor.setNodes(
        {
          url: null,
          link_type: 'resource',
          internal_resource_id,
          internal_template_resource_slot_id: null,
          internal_page_id: null,
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )

    const onButtonLinkResourceSlotChange = (internal_template_resource_slot_id: string) =>
      editor.setNodes(
        {
          url: null,
          link_type: 'resource',
          internal_template_resource_slot_id,
          internal_resource_id: null,
          internal_page_id: null,
        } as Partial<Node>,
        { at: [], match: (n: any) => n.id === block.id }
      )

    const sizeOptions = [
      { name: 'small', label: 'S' },
      { name: 'medium', label: 'M' },
      { name: 'large', label: 'L' },
    ];

    const alignOptions = [
      { name: 'left', label: <Icon icon="faAlignLeft" />, iconName: 'faAlignLeft' },
      { name: 'center', label: <Icon icon="faAlignCenter" />, iconName: 'faAlignCenter' },
      { name: 'right', label: <Icon icon="faAlignRight" />, iconName: 'faAlignRight' },
    ];

    const currentAlign = !!parentNode ? parentNode.align ? parentNode.align as string : 'left' : 'left';

    return (
      <PlateElement
        ref={ref}
        id={block.id}
        editor={editor}
        element={element}
        className={`${className} mx-[4px] py-4 page-block button-block`}
        contentEditable={false}
        suppressContentEditableWarning
        data-id={block.id}
        {...props}
      >
        <Popover open={!isReadOnly ? isPopoverOpen : false} onOpenChange={(open) => setIsPopoverOpen(open)}  >
          <PopoverTrigger>
            {isReadOnly
              ?
              <a
                href={goToLink ? goToLink : undefined}
                style={{
                  ...(block.primary_color && { backgroundColor: colorClassToRGB(block.primary_color, 100, activePalette.colors)! }),
                }}
                className={`
                  btn flex items-center justify-center  min-w-[100px] hover:scale-110
                  ${block.primary_color ? '' : 'btn-white'}
                  ${block.secondary_color ? block.secondary_color : ''}
                  ${parentNode?.button_size === 'medium' ? 'btn-large' : ''}
                  ${parentNode?.button_size === 'large' ? 'btn-xl' : ''}
                `}
                onClick={trackBtnClick.bind(trackBtnClick, goToLink)}
                {
                ...(block.link_type === 'external' || block.link_type === 'resource')
                  ? { target: "_blank", rel: "noopener noreferrer" } : { target: "_self" }}
              >
                {block.content}
              </a>
              :
              <div className="relative">
                <input
                  type="text"
                  style={{
                    width: `${(block?.content?.length || 1) + 3}ch`,
                    ...(block.primary_color && { backgroundColor: colorClassToRGB(block.primary_color, 100, activePalette.colors)! }),
                  }}
                  placeholder="..."
                  className={`
                    btn px-[12px]  min-w-[100px] hover:scale-110
                    ${block.primary_color ? 'border-0' : 'btn-white'}
                    ${block.secondary_color ? block.secondary_color : ''}
                    ${parentNode?.button_size === 'medium' ? 'btn-large' : ''}
                    ${parentNode?.button_size === 'large' ? 'btn-xl' : ''}
                  `}
                  value={content}
                  onChange={onContentChange}
                />
                {missingUrl &&
                  <div className="text-gray-700 flex min-w-[110px] font-semibold text-xs text-center absolute -bottom-5 left-1/2 transform -translate-x-1/2 font-sans">
                    <Icon icon='faLinkSlash' className="mx-1" />
                    No link added
                  </div>
                }
              </div>
            }
            {children}
          </PopoverTrigger>
          <PopoverContent
            onOpenAutoFocus={e => e.preventDefault()}
            onCloseAutoFocus={e => e.preventDefault()}
            side='top'
            sideOffset={10}
            align='center'
            className={`
            flex p-1 w-auto border-solid border border-gray-200 rounded-xl bg-gray-100 text-gray-900
            drop-shadow font-sans whitespace-nowrap bg-popover px-3 opacity-100 shadow-sm print:hidden
            `}
          >
            <ColorToolbarSelector
              textColor={element.secondary_color as string}
              buttonColor={element.primary_color as string}
              onButtonColorChange={onButtonColorChange}
              onTextColorChange={onTextColorChange}
            />
            <LinkToolbarSelector
              element={block}
              onUrlChange={onButtonUrlChange}
              onPageIdChange={onButtonLinkPageIdChange}
              template={template}
              pages={pages.filter(page => page.status === 'active')}
              setLinkResource={onButtonLinkResourceChange}
              setLinkResourceSlot={onButtonLinkResourceSlotChange}
            />
            {goToLink &&
              <TooltipLink
                tooltip="Follow link"
                side="top"
                container={block.id}
                icon='faArrowUpRightFromSquare'
                path={goToLink}
                linkClassName='btn-small hover:bg-gray-200 hover:rounded-sm'
                openInNewTab={(block.link_type === 'external' || block.link_type === 'resource')}
              />
            }
            <ToolbarDropdownSelector
              tooltip="Alignment"
              tooltipContainer={block.id}
              tooltipIcon={alignOptions.find((option) => option.name === currentAlign)!.iconName}
              onValueChange={onAlignmentChange}
              options={alignOptions}
              activeValue={parentNode?.align as string}
            />
            <ToolbarDropdownSelector
              tooltip="Size"
              tooltipContainer={block.id}
              tooltipIcon='faUpRightAndDownLeftFromCenter'
              onValueChange={onSizeChange}
              options={sizeOptions}
              activeValue={parentNode?.button_size as string}
            />
            <TooltipIcon
              tooltip="Delete"
              side="top"
              container={block.id}
              icon="faTrash"
              iconWrapperClassName="btn-small rounded-lg hover:bg-red-100"
              onClick={onButtonDelete}
            />
          </PopoverContent>
        </Popover>
      </PlateElement>
    );
  }
);

export default ButtonVoidElement

import React, { useState } from 'react';

import { Popover, PopoverTrigger, PopoverContent } from '../Popover/Popover';
import TooltipDropdown from '../ToolTip/TooltipDropdown';
import ButtonSelector from '../ButtonSelector/ButtonSelector';


type Props = {
  tooltip: string;
  tooltipContainer: string;
  tooltipIcon: string;
  onValueChange: (value: string) => void;
  options: {
    name: any,
    label: string | React.ReactElement,
    iconName?: string,
  }[],
  activeValue: string;
};

const ToolbarDropdownSelector: React.FC<Props> = ({ tooltip, tooltipContainer, tooltipIcon, onValueChange, options, activeValue }) => {
  const [popoverOpen, setPopoverOpen] = useState(false)

  const onValueHandle = (value: string) => {
    onValueChange(value)
    setPopoverOpen(false)
  }

  return (
    <Popover open={popoverOpen} onOpenChange={setPopoverOpen} modal={true}>
      <PopoverTrigger >
        <TooltipDropdown
          tooltip={tooltip}
          side='top'
          container={tooltipContainer}
          icon={tooltipIcon}
        />
      </PopoverTrigger>
      <PopoverContent
        align="center"
        sideOffset={5}
        onOpenAutoFocus={e => e.preventDefault()}
        onCloseAutoFocus={e => e.preventDefault()}
        contentEditable={false}
        suppressContentEditableWarning={true}
      >
        <div className="mt-2">
          <ButtonSelector
            data={options}
            current={activeValue}
            setCurrent={onValueHandle}
            wrapperClassName="ring-4 ring-white"
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ToolbarDropdownSelector;


import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';
import 'datatables.net';

export default class extends Controller {
  static values = {
    paging: { type: Boolean, default: false },
    info: { type: Boolean, default: false },
    searching: { type: Boolean, default: false },
    url: String,
  }

  myTable = null
  isDisconnecting = false

  connect() {
    console.log("RemoteTableController connect")
    if (!this.myTable) {
      console.log("tableOptions")
      console.log(this.tableOptions())
      const tableElement = this.element.matches('table')
        ? this.element
        : this.element.querySelector('table');
      this.myTable = $(tableElement).DataTable(this.tableOptions());
    }
    document.addEventListener('filters-updated', this.refreshTable.bind(this));
  }

  disconnect() {
    if (this.isDisconnecting) return;
    this.isDisconnecting = true;

    if (this.myTable) {
      this.myTable.destroy();
      this.myTable = null;
    }
    document.removeEventListener('filters-updated', this.refreshTable.bind(this));
  }

  // We can change the order by using the default HTML5 behavior for datatables.
  // table.table.w-full data-controller="table" data-order='[[0, "desc"]]'
  tableOptions() {
    return (
      {
        autoWidth: false,
        paging: this.pagingValue,
        info: this.infoValue,
        searching: this.searchingValue,
        dom: "rtip",
        lengthChange: this.lengthChange,
        pageLength: this.pageLength,
        order: [[1, 'asc']],
        columnDefs: this.columnDefs(),
        processing: true,
        serverSide: true,
        ajax: this.ajaxConfig(),
        createdRow: (row, data, dataIndex) => {
          // Assuming 'id' is the property in the row data that you want to use
          $(row).attr('id', data[0]);
        }
      }
    )
  }

  // We can set a couple of different values on the th element:
  // data-sortable="false" - Disable sorting for this column
  // data-td-class="text-center" - Add a class to the td element
  columnDefs() {
    return Array.from(this.element.querySelectorAll('th')).map((th, index) => {
      const tdClass = th.dataset.tdClass; // Get the data-td-class attribute value

      return {
        orderSequence: ['desc', 'asc'],
        targets: index,
        orderable: th.dataset.sortable !== 'false',
        createdCell: function (td, cellData, rowData, row, col) {
          // If there's a class defined, add it to the cell
          if (tdClass) {
            $(td).addClass(tdClass);
          }
        }
      };
    });
  }

  ajaxConfig() {
    if (!this.hasUrlValue) {
      return false; // Disable AJAX if no remote URL is provided
    }

    return {
      url: this.urlValue, // Use the remoteUrlValue from static values
    };
  }

  search(event) {
    console.log("remoteTableController search")
    return this.myTable.search(event.target.value).draw()
  }

  // Default is false
  get lengthChange() {
    return this.element.dataset.lengthChange === "true"
  }

  // Default is 100
  get pageLength() {
    return this.element.dataset.pageLength ? parseInt(this.element.dataset.pageLength) : 100
  }

  refreshTable(event) {
    console.log("remoteTableController refreshTable")
    console.log(event)
    if (this.myTable) {
      this.myTable.ajax.reload(null, false); // false to retain the current pagination
    }
  }
}
